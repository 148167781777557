import React, { Component } from "react";
import { LoadingShape } from 'react-rainbow-components';

class NewPools extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.newPools.length === 0) {
      return (
        <>
        <div className="card dashboard-list box-shadow">
            <h5 className="dashboard-list-title">New Pools</h5>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <a href="#" className="btn btn-link btn-link-white btn-sm mx-auto mt-2 mb-0">View all pools</a>
        </div>
        </>
      );
    }
    let newPools = this.props.newPools.map(function (newPool, index) {
        return (
        <React.Fragment key={index}>
        <div className="dashboard-list-item" key={index}>
            <img src={newPool.pool_img ? newPool.pool_img : "../assets/pools/placeholder.jpg"} alt="pool_image"></img>
            <div className="dashboard-list-item-content">
                <div className="dashboard-list-item-info">
                    <a href={`../pool/${newPool.pool_id}`}><h4>{newPool.pool_title}</h4></a>
                    <p>{newPool.pool_desc}</p>
                </div>
            </div>
        </div>
        <div className="dashboard-list-separator" key={`separator-${index}`}></div>
        </React.Fragment>
      );
    }, this);

    return (
        <div className="card dashboard-list box-shadow">
            <h5 className="dashboard-list-title">New Pools</h5>
            {newPools}
            <a href="/dashboard/all-pools" className="btn btn-link btn-link-white btn-sm mx-auto mt-2 mb-0">View all pools</a>
        </div>
    )
  }
}

export default NewPools;