import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import "./mobileHeader.css";

class MobileHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
    };

    this.logout = this.logout.bind(this);
  }

  showSidebar() {
    // console.log("HI");
  }

  backBtn() {
    // console.log("Back <-");
  }

  toggleMenu() {
    document.getElementById("wrapper-menu").classList.toggle("open");
    document.getElementById("navbarToggler").classList.toggle("show");
  }

  logout() {
    sessionStorage.setItem("userData", "");
    sessionStorage.clear();
    localStorage.setItem("userData", "");
    localStorage.clear();
    this.setState({ redirectToReferrer: true });
  }

  render() {
    return (
      <div className="px-0 mobile-header">
        {/* {this.props.theme = ? navbar-white : navbar-color} */}
        <nav
          className={`navbar navbar-expand-lg ${
            this.props.theme === "color" ? "navbar-color" : this.props.theme === "white" ? "navbar-white" : "navbar-photo"
          }`}
        >
          {this.props.back ? (
            <Link to={this.props.backLink}>
              <button
                className="navbar-button"
                type="button"
                //onClick={this.showSidebar}
                //href="/login"
                onClick={this.backBtn.bind(this)}
              >
                <div className="arrow-back"></div>
              </button>
            </Link>
          ) : (
            <button disabled className="navbar-button" type="button"></button>
          )}

          <div>
            <h3 className="text-center">{this.props.name}</h3>
          </div>
          {this.props.menu ? (
            <button
              className="navbar-button navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarToggler"
              aria-controls="navbarToggler"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleMenu.bind(this)}
            >
              <div className="wrapper-menu" id="wrapper-menu">
                <div className="line-menu half start"></div>
                <div className="line-menu"></div>
                <div className="line-menu half end"></div>
              </div>
            </button>
          ) : (
            <button disabled className="navbar-button" type="button"></button>
          )}
          <div className="collapse navbar-collapse" id="navbarToggler">
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              {sessionStorage.getItem("userData") && (
                <li className="nav-item">
                <a className="nav-link" href="/my-profile">
                  My Profile
                </a>
              </li>
              )}
              <li className="nav-item">
                <a className="nav-link" href="/faq">
                  FAQ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  Contact
                </a>
              </li>
              {!sessionStorage.getItem("userData") && (
                <li className="nav-item">
                <a className="nav-link" href="/signup">
                  Sign Up
                </a>
              </li>
              )}
              {sessionStorage.getItem("userData") ? (
                <li className="nav-item">
                  <a href="#" onClick={this.logout} className="nav-link">
                    Logout
                  </a>
                  {this.state.redirectToReferrer && <Redirect to="/login" />}
                </li>
              ) : (
                <li className="nav-item">
                  <a href="/login" className="nav-link">
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default MobileHeader;
