import React, { Component } from "react";
import { Header, Footer } from "./components";
import "./styles/foundation.min.css";
import "./styles/custom.css";
import Routes from "./routes";
//import Header from './components/header/header';
//import Footer from './components/footer/footer';
//import MobileHeader from './components/mobileHeader/mobileHeader';

class App extends Component {
  constructor() {
    super();
    this.state = {
      appName: "Baby Betz",
      home: false,
    };
  }

  render() {
    return (
      <>
        <div className="off-canvas-wrapper">
          <div className="off-canvas-wrapper-inner" data-off-canvas-wrapper>
            {/* <div className="off-canvas-content" data-off-canvas-content> */}
            {/* <MobileHeader name={this.state.appName}/> */}
            {/* <Header name={this.state.appName}/> */}
            <Routes name={this.state.appName} />
            {/* </div> */}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default App;
