import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import { PostData } from "../../services/postData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Application, Breadcrumb, Breadcrumbs, TableWithBrowserPagination, Column, Badge, Avatar } from 'react-rainbow-components';
import "./dashboard.scss";

const theme = {
    rainbow: {
        palette: {
            brand: '#00c7c0',
        },
    },
};
const Sex = ({ value }) => value==='F'?<FontAwesomeIcon icon={faVenus} size="lg" className="girl-color" />:<FontAwesomeIcon icon={faMars} size="lg" className="boy-color" />;
const UserAvatar = ({ value }) => <Avatar
  src={value}
  icon={<FontAwesomeIcon icon={faUser} />}
  size="medium"
  style={{'color': 'white'}}
  className="m-1 ml-2"
/>;
const FormatDate = ({ value }) => {
  const dateObject = new Date(new Date(value).getTime() + Math.abs(new Date(value).getTimezoneOffset()*60000));
  const formattedDate = dateObject.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
  return formattedDate;
};
const Time = ({ value }) => value.slice(0, 5);
const Weight = ({ value }) => parseFloat(value) < 50 ? parseFloat(value).toFixed(2) + " kg" : parseInt(value / 16) + " lbs" + ", " + Math.round(value % 16) + " oz";
const Length = ({ value }) => parseFloat(value) > 30 ? (parseFloat(value).toFixed(1) + " cm") : parseFloat(value).toFixed(2) + '"';
const Pool = ({ value }) => { return <a href={`/pool/${value}`} className='btn-link btn-link-white mr-2'>{value}</a> };

class AllBabies extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "All Babies",
        back: true,
        backLink: "/dashboard",
        menu: true,
        menuLink: "",
      },
      activePage: 1,
      pageSize: 10,
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData")).userData.admin) {
      this.getAllBabies();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  getAllBabies() {
    PostData("getDashboardAllBabies", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.allBabies) {
        this.setState({ allBabies: responseJson.allBabies });
      } else {
        console.log('Fetching failed at getAllBabies()');
      }
    });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/signup"} />;
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="boxed breadcrumbs mt-4">
        <Breadcrumbs>
            <Breadcrumb label="Dashboard" href="/dashboard" />
            <Breadcrumb label={this.state.navbar.name} />
        </Breadcrumbs>
      </div>
      <Application theme={theme}>
      <div className="dashboard-lists mb-4">
        <div className="card dashboard-list box-shadow py-5 px-0 mb-4 mt-1">
          <TableWithBrowserPagination
            showRowNumberColumn
            rowNumberOffset={this.state.activePage * this.state.pageSize - this.state.pageSize}
            pageSize={this.state.pageSize}
            data={this.state.allBabies}
            keyField="res_id"
            style={{width:'100%'}}>
          <Column defaultWidth={60} header="" field="baby_img" component={UserAvatar} />
          <Column defaultWidth={230} header="Baby" field="name" />
          <Column defaultWidth={80} header="Sex" field="gender" headerAlignment="center" cellAlignment="center" component={Sex} />
          <Column defaultWidth={230}header="DoB" field="date" headerAlignment="center" cellAlignment="center" component={FormatDate} />
          <Column header="Time" field="time" headerAlignment="center" cellAlignment="center" component={Time} />
          <Column header="Weight" field="weight" headerAlignment="center" cellAlignment="center" component={Weight} />
          <Column header="Length" field="length" headerAlignment="center" cellAlignment="center" component={Length} />
          <Column header="Pool" field="pool_id" headerAlignment="right" cellAlignment="right" component={Pool} />
          </TableWithBrowserPagination>
        </div>
      </div>
      </Application>
      </>
    );
  }
}
export default AllBabies;
