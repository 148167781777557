import React, { Component } from "react";
import "./footer.scss";
import { images } from "../../constants";

class Footer extends Component {
  render() {
    return (
      <>
        <div className="app__footer" id="footer">
            <img src={images.logo_white} alt="Square Seed logo white" />
            <p className="text-center text-white py-2"> by </p>
            <img src={images.square_seed_white} alt="Square Seed logo white" className="mb-5"/>
        </div>
        <p className="app__footer-copyright text-center text-white">
            &copy;2024 &nbsp;&bull;&nbsp; <a href="https://www.squareseed.co">Square Seed</a>
        </p>
      </>
    );
  }
}
export default Footer;
