import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import { Header } from "..";
import MobileHeader from "../mobileHeader/mobileHeader";
import "../../styles/floating-labels.css";

class Forgot extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Forgot Password?",
        back: true,
        backLink: "/login",
        menu: true,
        menuLink: "",
      },
      email: "",
      redirectToReferrer: false,
      loading: "",
      errors: {},
    };

    this.reset = this.reset.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    let errors = {};
    console.log(this.state);
    if (!this.props.location.state) {
      errors["alert_error"] = "";
    } else {
      errors["alert_error"] = this.props.location.state.error;
      document.getElementById("alert_error").classList.add("alert");
    }
    this.setState({ errors: errors });
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.email === "") {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
      document.getElementById("inputEmail").classList.add("is-invalid");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        document.getElementById("inputEmail").classList.remove("is-invalid");
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  reset() {
    document.getElementById("alert_error").classList.remove("alert");
    document.getElementById("alert").classList.remove("alert");
    document.getElementById("alert_info").classList.remove("alert");
    if (this.validate()) {
      let errors = {};
      this.setState({ loading: true });
      PostData("reset", {email: this.state.email}).then((result) => {
        let responseJson = result;
        if (responseJson.resetToken) {
          sessionStorage.setItem("resetToken", JSON.stringify(responseJson));
          this.setState({ loading: false });
          console.log(responseJson.resetToken);
          errors["reset_info"] = "You should receive an instruction email with a link to reset your password.";
          document.getElementById("alert_info").classList.add("alert");
          this.setState({ errors: errors });
        } else {
          //alert(result.error);
          errors["reset_error"] = "Sorry, that email address doesn't match our records.";
          document.getElementById("alert").classList.add("alert");
          this.setState({ loading: false });
          this.setState({
            errors: errors,
          });
          
          console.log(responseJson.resetToken);
        }
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/my-profile"} />;
    }
    if (sessionStorage.getItem("userData")) {
      return <Redirect to={"/my-profile"} />;
    }
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="white"
        redirectToReferrer={!this.state.redirectToReferrer}
      />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed mb-4" id="Body">
        <div className="backdrop box-shadow">
          <div className="medium-12 columns left">
            <div className="alert-danger" role="alert" id="alert_error">
              {this.state.errors.alert_error}
            </div>
            <h6 className="center mb-4">No worries! We're here to help.<br/>Let's reset that password.</h6>
            <div className="form-label-group signup-label">
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control"
                placeholder="email"
                required=""
                autoFocus=""
                onChange={this.onChange}
              />
              <label htmlFor="inputEmail">Email</label>
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="alert-danger" role="alert" id="alert">
              {this.state.errors.reset_error}
            </div>
            <div className="alert-info" role="alert" id="alert_info">
              {this.state.errors.reset_info}
            </div>
            <button
              className="btn btn-lg btn-primary btn-block mt-5"
              type="submit"
              onClick={this.reset}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Reset Password
            </button>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default Forgot;
