import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import { PostData } from "../../services/postData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Application, Breadcrumb, Breadcrumbs, TableWithBrowserPagination, Column, Badge, Avatar } from 'react-rainbow-components';
import "./dashboard.scss";

const theme = {
    rainbow: {
        palette: {
            brand: '#00c7c0',
        },
    },
};
const StatusBadge = ({ value }) => {
  if (value) {
    let limitDate = new Date(value);
    let now = new Date();
    if (new Date(limitDate.setDate(limitDate.getDate() + 1)) < now) {
      return <Badge label="CLOSED" />
    } else if (new Date(limitDate.setDate(limitDate.getDate() - 3)) < now) {
      return <Badge label="Closing Soon" variant='warning' style={{'color': 'white', 'backgroundColor': '#00c7c0'}} />
    } else { return <Badge label = "OPEN" variant='outline-brand' />}
  } else { return <Badge label='error' variant='error' /> }
}
const Baby = ({ value }) => value?<Badge label={'Yep'} variant='brand' /> : <Badge label={'Not Yet'} variant='lightest' />;
const PoolAvatar = ({ value }) => <Avatar
  src={value}
  icon={<FontAwesomeIcon icon={faUser} />}
  size="medium"
  style={{'color': 'white'}}
  className="m-1 ml-2"
/>;
const Pool = ({ value }) => { return <a href={`/pool/${value}`} className='btn-link btn-link-white'>{value}</a> }
const Betz = ({ value }) => { return <span className={value === '0' && 'light-color'}>{value}</span> }

class AllPools extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "All Pools",
        back: true,
        backLink: "/dashboard",
        menu: true,
        menuLink: "",
      },
      activePage: 1,
      pageSize: 10,
      allPools: [],
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData")).userData.admin) {
      this.getAllPools();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  getAllPools() {
    PostData("getDashboardAllPools", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.allPools) {
        this.setState({ allPools: responseJson.allPools });
      } else {
        console.log('Fetching failed at getAllPools()');
      }
    });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/signup"} />;
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="boxed breadcrumbs mt-4">
        <Breadcrumbs>
            <Breadcrumb label="Dashboard" href="/dashboard" />
            <Breadcrumb label={this.state.navbar.name} />
        </Breadcrumbs>
      </div>
      <Application theme={theme}>
      <div className="dashboard-lists mb-4">
        <div className="card dashboard-list box-shadow px-0 py-5 mb-4 mt-1">
          <TableWithBrowserPagination
            showRowNumberColumn
            rowNumberOffset={this.state.activePage * this.state.pageSize - this.state.pageSize}
            pageSize={this.state.pageSize}
            data={this.state.allPools}
            keyField="pool_id"
            style={{width:'100%'}}>
          <Column defaultWidth={60} header="" field="pool_img" component={PoolAvatar} />
          <Column header="Pool" field="pool_title" cellAlignment="left" />
          <Column header="Description" field="pool_desc" cellAlignment="left" />
          <Column defaultWidth={200} header="Status" field="limit_date" headerAlignment="center" cellAlignment="center" component={StatusBadge} />
          <Column defaultWidth={200} header="Pool ID" field="pool_id" component={Pool} />
          <Column defaultWidth={100} header="# of Betz" field="betz" headerAlignment="center" cellAlignment="center" component={Betz} />
          <Column defaultWidth={100} header="Baby" field="has_results" headerAlignment="center" cellAlignment="center" component={Baby} />
          </TableWithBrowserPagination>
        </div>
      </div>
      </Application>
      </>
    );
  }
}
export default AllPools;
