import React, { Component } from "react";
import CountUp from "react-countup";
import { confirmAlert } from "react-confirm-alert";
import { LoadingShape } from 'react-rainbow-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare, faPen, faBaby, faCommentSms, faListCheck, faQuestion, faLock } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faCopy } from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faXTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./poolInfo.scss";

class PoolInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pool_id: "",
      website: "app.squareseed.co",
      share_msg: ""
    }

    this.sharePool = this.sharePool.bind(this);
  }

  formatDate(fetchDate) {
    let date = fetchDate.split("-");
    let mm = date[1];
    let dd = date[2];
    switch (this.props.date_format) {
      case "dd-mm-yyyy":
        return `${dd}/${mm}`;
      case "mm-dd-yyyy":
        return `${mm}/${dd}`;
      case "yyyy-mm-dd":
        return `${mm}/${dd}`;
    }
  }

  sharePool() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="share-pool-popup">
            <h2 className="mb-4">Share this pool?</h2>
            <p>Invite friends and family to join the fun using social media and/or email.</p>
            <div className="share-social-icons">
              <a className="share-social-icon facebook" title="Share on Facebook" rel="noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.website}/pool/${this.props.pool_id}`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a className="share-social-icon twitter" rel="noreferrer" href={`http://twitter.com/share?url=https://${this.state.website}/pool/${this.props.pool_id}&text=My%20baby%20pool%20is%20ready%20for%20your%20predictions!%20Place%20your%20bet%20on%20when%20the%20baby%20will%20arrive`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faXTwitter} /></a>
              <a className="share-social-icon whatsup" rel="noreferrer" href={`https://wa.me/?text=https%3A%2F%2F${this.state.website}%2Fpool%2F${this.props.pool_id}`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faWhatsapp} /></a>
              <a className="share-social-icon email" rel="noreferrer" href={`mailto:?body=The%20${this.props.pool_title}%20baby%20pool%20is%20ready%20for%20your%20predictions%21%20Place%20your%20bet%20on%20when%20the%20baby%20will%20arrive.%0A%0Ahttps%3A%2F%2F${this.state.website}%2Fpool%2F${this.props.pool_id}&subject=Place%20your%20bets%20in%20the%20${this.props.pool_title}%21`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faEnvelope} /></a>
              <a className="share-social-icon sms" rel="noreferrer" href={`sms:?&body=Place%20your%20bets%20in%20the%20${this.props.pool_title}%21%0A%0AThe%20${this.props.pool_title}%20baby%20pool%20is%20ready%20for%20your%20predictions%21%20Place%20your%20bet%20on%20when%20the%20baby%20will%20arrive.%0A%0Ahttps%3A%2F%2F${this.state.website}%2Fpool%2F${this.props.pool_id}`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faCommentSms} /></a>
              <button className="share-social-icon clipboard" onClick={() => { this.copy(); onClose() }}><FontAwesomeIcon icon={faCopy}/></button>
            </div>
          </div>
        );
      }
    });
  }

  copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("The link to your baby pool has been copied to your clipboard.")
  }

  render() {
    return (
      <>
      {new Date(this.props.limit_date).setDate(new Date(this.props.limit_date).getDate() - 2) < new Date() && new Date(this.props.limit_date).setDate(new Date(this.props.limit_date).getDate() + 1) > new Date() &&
        <div className="boxed mb-4">
          <div class="alert alert-primary text-center mx-3" role="alert">
            This pool is closing soon! <br className="d-sm-none" />Place your betz by {this.formatDate(this.props.limit_date)}
          </div>
        </div>}
      <div className="pool-info-circle-lip-white box-shadow mb-4">
      </div>
      <div className="pool_info">
        {/* <div className="top-color pool-info-circle-lip-color"></div> */}
        <div className="pool_info-top padding-on-large">
          <div className="pool_info-top-item pool_info-due-date">
            <p>DUE DATE</p>
            <h2>{this.props.due_date ? this.formatDate(this.props.due_date) : "--/--"}</h2>
          </div>
          <div className="pool_info-img"><img src={ this.props.pool_img ? this.props.pool_img : "/assets/pools/placeholder.png" } alt="pool_img" /></div>
          <div className="pool_info-top-item pool_info-no-bets">
            <div className="">
              <p>{this.props.numberOfBetz !== 1 ? "BETS" : "BET"}</p>
              <h2>{this.props.numberOfBetz ? (<CountUp
                start={0}
                end={this.props.numberOfBetz}
                duration={2}
                key="betz"
              ></CountUp>) : "0"}</h2>
            </div>
          </div>
        </div>
        <div className="pool_info-card">
          <div className="pool_info-btns">
            <div className="pool_info-btn pool_info-edit">
            {sessionStorage.getItem("userData") &&
            JSON.parse(sessionStorage.getItem("userData")).userData.user_id ===
                this.props.pool_owner ? (
                    <a href={`/pool/${this.props.pool_id}/edit-pool`}><FontAwesomeIcon icon={faPen} size="lg" /></a>
            ) : (
              <a href={`/faq`}><FontAwesomeIcon icon={faQuestion} size="lg" /></a>
            )}</div>
            <div className="pool_info-btn-spacer"></div>
            <div className="pool_info-btn pool_info-share">
              <button
                onClick={this.sharePool}
                className="pool_info-btn pool_info-share"
              >
              <FontAwesomeIcon icon={faShare} size="lg" />
              </button>
            </div>
          </div>
          {this.props.pool_title ? <h5 className="card-title">{this.props.pool_title}</h5> : <div className="rainbow-m-bottom_small mx-auto my-2 mt-sm-3" style={{height: '30px', width: '200px'}}>
              <LoadingShape />
            </div>}
          {this.props.pool_desc ? <p className="card-text">{this.props.pool_desc}</p> : <div className="rainbow-m-bottom_small mx-auto mb-4" style={{height: '20px', width: '300px'}}>
              <LoadingShape className="mb-2" /> </div> }
          <div className={`pool_info-place-bets${sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData")).userData.user_id === this.props.pool_owner ? '' : ' mb-4'}`}>
          {new Date(this.props.limit_date).setDate(new Date(this.props.limit_date).getDate() + 1) > new Date() ? <a href={`/pool/${this.props.pool_id}/place-betz`}><FontAwesomeIcon icon={faListCheck} size="lg" className="icon-inline" /> Place Your Betz</a> : <a href="#"><FontAwesomeIcon icon={faLock} size="lg" className="icon-inline" /> Pool is Closed</a> }
          </div>
          {sessionStorage.getItem("userData") &&
            JSON.parse(sessionStorage.getItem("userData")).userData.user_id ===
              this.props.pool_owner &&
              <div className="pool_info-announce"><a href={`/pool/${this.props.pool_id}/add-results`}><FontAwesomeIcon icon={faBaby} size="lg" className="icon-inline"/> Baby is Here</a></div>
            }
          <div className="top-color"></div>
          <div className="pool-info-circle-lip-color"></div>
        </div>
      </div>
      </>
    );
  }
}
export default PoolInfo;
