import React, { Component } from "react";
import { LoadingShape } from 'react-rainbow-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faVenusMars } from "@fortawesome/free-solid-svg-icons";

class NewBabies extends Component {
  constructor(props) {
    super(props);
  }

  formatDate(fetchDate) {
    let date = fetchDate.split("-");
    let yy = date[0];
    let mm = date[1];
    let dd = date[2];
    return `${mm}/${dd}/${yy}`;
  }

  render() {
    if (this.props.newBabies.length === 0) {
      return (
        <>
        <div className="card dashboard-list box-shadow">
            <h5 className="dashboard-list-title">New Babies</h5>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'150px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'100px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                    <div className="dashboard-list-item-status">
                        <FontAwesomeIcon icon={faVenusMars} />
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'150px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'100px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                    <div className="dashboard-list-item-status">
                        <FontAwesomeIcon icon={faVenusMars} />
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'150px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'100px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                    <div className="dashboard-list-item-status">
                        <FontAwesomeIcon icon={faVenusMars} />
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'150px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'100px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                    <div className="dashboard-list-item-status">
                        <FontAwesomeIcon icon={faVenusMars} />
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <div className="dashboard-list-item">
                <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <div style={{width:'150px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                        <div style={{width:'100px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                    </div>
                    <div className="dashboard-list-item-status">
                        <FontAwesomeIcon icon={faVenusMars} />
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator"></div>
            <a href="#" className="btn btn-link btn-link-white btn-sm mx-auto mt-2 mb-0">View all babies</a>
        </div>
        </>
      );
    }
    let newBabies = this.props.newBabies.map(function (newBaby, index) {
        return (
        <React.Fragment key={index}>
        <div className="dashboard-list-item" key={index}>
            <img src={newBaby.baby_img ? newBaby.baby_img : "../assets/babies/placeholder.jpg"} alt="baby_image"></img>
            <div className="dashboard-list-item-content">
                <div className="dashboard-list-item-info">
                    <a href={`../pool/${newBaby.pool_id}`}><h4>{newBaby.name}</h4></a>
                    <p>{this.formatDate(newBaby.date)}</p>
                </div>
                <div className="dashboard-list-item-status">
                    {newBaby.gender === "M" ? <FontAwesomeIcon icon={faMars} size="lg" className='boy-color' /> : <FontAwesomeIcon icon={faVenus} size="lg" className='girl-color' />}
                </div>
            </div>
        </div>
        <div className="dashboard-list-separator" key={`separator-${index}`}></div>
        </React.Fragment>
      );
    }, this);

    return (
        <>
        <div className="card dashboard-list box-shadow">
            <h5 className="dashboard-list-title">New Babies</h5>
            {newBabies}
            <a href="/dashboard/all-babies" className="btn btn-link btn-link-white btn-sm mx-auto mt-2 mb-0">View all babies</a>
        </div>
        </>
    )
  }
}

export default NewBabies;