import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "../../components";
import AvatarEditor from "react-avatar-editor";
import { ToggleButtonGroup, ToggleButton, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faCheck, faCalendarCheck, faClock } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, TimePicker } from "react-rainbow-components";
import "../../styles/custom.css";
import "./editResults.css";

class EditResults extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Update Results",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      pool_id: "",
      pool_title: "",
      pool_desc: "",
      date_format: "",
      due_date: "",
      time_format: "",
      units: "",
      theme: "",
      color: "",
      gender: "",
      weight: "",
      baby_img: "",
      canvas: "",
      img_width: 400,
      img_height: 400,
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 100,
      preview: null,
      minWeightImperial: "80",
      avgWeightImperial: "120",
      maxWeightImperial: "160",
      minWeightMetric: "2",
      avgWeightMetric: "3.5",
      maxWeightMetric: "5",
      length: "",
      minLengthImperial: "18",
      avgLengthImperial: "20",
      maxLengthImperial: "22",
      minLengthMetric: "40",
      avgLengthMetric: "50",
      maxLengthMetric: "60",
      date: "",
      time: "",
      name: "",
      locale: { name: "en-US", label: "English (US)" },
      poolLoaded: false,
      errors: {},
      redirectToReferrer: false,
    };

    this.getPoolUnits = this.getPoolUnits.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeLength = this.onChangeLength.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.setCanvas = this.setCanvas.bind(this);
    this.editResults = this.editResults.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData")) {
      this.setPoolId();
    } else {
      this.setPoolId();
    }
  }

  setPoolId() {
    this.setState({ pool_id: this.props.match.params.pool_id }, () => {
      this.getPoolUnits();
    });
  }

  getPoolUnits() {
    PostData("poolUnits", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.poolUnits) {
        // sessionStorage.setItem("poolUnits", JSON.stringify(responseJson));
        this.setState({ poolLoaded: true });

        let due_date = new Date(responseJson.poolUnits.due_date + " 14:00 UTC");
        this.setState({
          date_format: responseJson.poolUnits.date_format,
          due_date: due_date.toISOString(),
          //date: due_date.toISOString(),
          time_format: responseJson.poolUnits.time_format,
          units: responseJson.poolUnits.units,
          theme: responseJson.poolUnits.theme,
          color: responseJson.poolUnits.color,
        });

        switch (responseJson.poolUnits.units) {
          case "metric":
            this.setState({ weight: this.state.avgWeightMetric });
            this.setState({ length: this.state.avgLengthMetric });
            break;
          case "both":
            this.setState({ weight: this.state.avgWeightImperial });
            this.setState({ length: this.state.avgLengthImperial });
            break;
          default:
            this.setState({ weight: this.state.avgWeightImperial });
            this.setState({ length: this.state.avgLengthImperial });
        }
        this.getResults();
      } else alert(result.error);
    });
  }

  getResults() {
    if (this.state.pool_id) {
      PostData("getResults", { pool_id: this.state.pool_id }).then((result) => {
        let responseJson = result;
        if (responseJson.poolResults) {
          // sessionStorage.setItem("poolResults", JSON.stringify(responseJson));
          let date = new Date(responseJson.poolResults.date + " 14:00 UTC");
          this.setState({
            name: responseJson.poolResults.name,
            // baby_img: `../../${responseJson.poolResults.baby_img}`,
            baby_img: responseJson.poolResults.baby_img,
            gender: responseJson.poolResults.gender,
            weight: parseFloat(responseJson.poolResults.weight),
            length: parseFloat(responseJson.poolResults.length),
            date: date.toISOString(),
            time: responseJson.poolResults.time,
            // redirectToReferrer: true
          });
          document.getElementById("formBasicRangeWeight").value = this.state.weight;
          document.getElementById("formBasicRangeLength").value = this.state.length;
        } else alert(result.error);
      });
    }
  }

  onChangeGender(value) {
    this.setState({ gender: value }, () => {
      let error = "";
      document.getElementById("inputGender").classList.remove("is-invalid");

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.gender = error;
        return { errors };
      });
    });
  }

  onChangeWeight(e) {
    this.setState({ weight: e.target.value });
  }

  onChangeLength(e) {
    this.setState({ length: e.target.value });
  }

  onChangeDate(value) {
    this.setState({ date: value }, () => {
      let error = "";

      if (this.state.date === "") {
        error = "Date is required.";
        document.getElementById("datePicker").classList.add("is-invalid");
      } else {
        document.getElementById("datePicker").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.date = error;
        return { errors };
      });
    });
  }

  onChangeTime(value) {
    this.setState({ time: value }, () => {
      let error = "";

      if (this.state.time === "") {
        error = "Time is required.";
        document.getElementById("timePicker").classList.add("is-invalid");
      } else {
        document.getElementById("timePicker").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.time = error;
        return { errors };
      });
    });
  }

  onChangeName(e) {
    this.setState({ name: e.target.value }, () => {
      let error = "";

      if (this.state.name === "") {
        error = "Please enter baby's name.";
        document.getElementById("inputName").classList.add("is-invalid");
      } else {
        document.getElementById("inputName").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.name = error;
        return { errors };
      });
    });
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.time === "") {
      isValid = false;
      errors["time"] = "Please select a time of birth.";
      document.getElementById("timePicker").classList.add("is-invalid");
      document.getElementById("timePickerContainer").scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    } else {
      document.getElementById("timePicker").classList.remove("is-invalid");
    }

    if (this.state.date === "") {
      isValid = false;
      errors["date"] = "Please select a birth date.";
      document.getElementById("datePicker").classList.add("is-invalid");
      document.getElementById("datePickerContainer").scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    } else {
      document.getElementById("datePicker").classList.remove("is-invalid");
    }

    if (this.state.gender === "") {
      isValid = false;
      errors["gender"] = "Please select one of the genders.";
      document.getElementById("inputGender").classList.add("is-invalid");
      document.getElementById("inputGenderContainer").scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    } else {
      document.getElementById("inputGender").classList.remove("is-invalid");
    }

    if (this.state.name === "") {
      isValid = false;
      errors["name"] = "Please enter baby's name.";
      document.getElementById("inputName").classList.add("is-invalid");
      document.getElementById("inputName").scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    } else {
      document.getElementById("inputName").classList.remove("is-invalid");
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  handleNewImage = (e) => {
    this.setState({ baby_img: e.target.files[0] });
    this.setState({ scale: 1.125 })
    document.getElementById("zoom-control").value = 1.125;
    document.getElementById("fileInputLabel").innerText = e.target.files[0].name;
  };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  setCanvas() {
    setTimeout(
      function () {
        document.getElementById(
          "hidden-data"
        ).value = this.editor.getImageScaledToCanvas().toDataURL();
      }.bind(this),
      50
    );
    setTimeout(
      function () {
        let canvas = document.getElementById("hidden-data").value;
        this.setState({ canvas }, () => this.editResults());
      }.bind(this),
      100
    );
  }

  editResults() {
    document.getElementById("alert").classList.remove("alert");
    if (this.validate()) {
      let errors = {};
      let postData = {
        pool_id: this.state.pool_id,
        name: this.state.name,
        baby_img: this.state.canvas,
        gender: this.state.gender,
        weight: this.state.weight,
        length: this.state.length,
        date: this.state.date,
        time: this.state.time,
        date_format: this.state.date_format,
        time_format: this.state.time_format,
        units: this.state.units
      };
      this.setState({ loading: true });
      PostData("editResults", postData).then((result) => {
        let responseJson = result;
        this.setState({ message: "" });
        if (responseJson.newPoolResults) {
          // sessionStorage.setItem("newPoolResults", JSON.stringify(responseJson));
          this.setState({ loading: false });
          this.setState({ redirectToReferrer: true });
        } else {
          errors["editResults"] = result.error;
          document.getElementById("alert").classList.add("alert");
          this.setState({ loading: false });
          this.setState({
            errors: errors,
          });
        }
      });
    }
  }

  setEditorRef = (editor) => (this.editor = editor);

  render() {
    let pool_id = this.props.match.params.pool_id;
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/pool/${pool_id}`} />;
    }

    if (!this.state.poolLoaded) {
      return (
        <>
        <Header theme="color"/>
        <MobileHeader
          name={this.state.navbar.name}
          back={this.state.navbar.back}
          backLink={`/pool/${pool_id}`}
          theme="color"
          menu={this.state.navbar.menu}
          menuLink={this.state.navbar.menuLink}
        />
        <div className="card pool-card box-shadow text-center py-4 mt-3">
          <div className="card-body px-4 py-2">
            <h4 className="card-text my-4">Loading...</h4>
          </div>
        </div>
        </>
      );
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={`/pool/${pool_id}`}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="my_pools-circle-lip my_pools-circle-lip-shorter box-shadow mb-n5">
        <div className="add-results-header text-center d-flex flex-column justify-content-center h-100 mt-n3 px-5">
          <h3>Made a mistake?</h3>
          <p>Don't worry, we got you covered! You can still make changes on the birth results.</p>
        </div>
      </div>
      <div className="boxed" id="Body">
        <div className="medium-12 columns">
          <div className="card text-center box-shadow py-4">
            <h4>Welcome</h4>
            <div className="medium-12 columns text-left">
              <div className="form-label-group signup-label">
                <input
                  type="text"
                  name="name"
                  id="inputName"
                  className="form-control"
                  placeholder="Baby's Name"
                  value={this.state.name}
                  required
                  onChange={this.onChangeName}
                />
                <label htmlFor="inputName">Baby's Name</label>
                <div className="invalid-feedback">{this.state.errors.name}</div>
              </div>
            </div>
          </div>
          <div className="card text-center box-shadow p-4">
            <h4>Baby's Photo</h4>
            <div className="img-scaler">
              <textarea id="hidden-data" />
              <AvatarEditor
                ref={this.setEditorRef}
                scale={parseFloat(this.state.scale)}
                width={this.state.img_width}
                height={this.state.img_height}
                position={this.state.position}
                onPositionChange={this.handlePositionChange}
                rotate={parseFloat(this.state.rotate)}
                borderRadius={
                  this.state.img_width / (100 / this.state.borderRadius)
                }
                color={[255, 255, 255, 0.6]}
                image={
                  this.state.baby_img
                    ? this.state.baby_img
                    : "/assets/babies/placeholder.jpg"
                    // : "/assets/pools/placeholder_pool.png"
                }
                className="editor-canvas"
              />
            </div>
            <div className="form-group">
              <label htmlFor="formControlRange">Zoom</label>
              <input
                type="range"
                className="form-control-range"
                id="zoom-control"
                name="scale"
                step="0.01"
                min={this.state.allowZoomOut ? "0.1" : "1"}
                max="2"
                defaultValue={parseFloat(this.state.scale)}
                onChange={this.handleScale}
              />
            </div>
            <br />
            <div className="custom-file">
              <input
                type="file"
                id="fileInput"
                className="custom-file-input"
                accept="image/*"
                onChange={this.handleNewImage}
              />
              <label
                className="custom-file-label"
                htmlFor="fileInput"
                id="fileInputLabel"
              >
                Add a New Image
              </label>
            </div>
          </div>
          <div className="card text-center box-shadow p-4" id="inputGenderContainer">
            <h4>Sex</h4>
            <ToggleButtonGroup
              type="radio"
              name="gender"
              id="inputGender"
              className="gender-button-group"
              value={this.state.gender}
              onChange={this.onChangeGender}
            >
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"M"}
                className="gender-button gender-button-boy"
              >
                <span></span>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className="toggle-button-checkmark"
                />
                <FontAwesomeIcon icon={faMars} size="4x" />
                Boy
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"F"}
                className="gender-button gender-button-girl"
              >
                <span></span>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className="toggle-button-checkmark"
                />
                <FontAwesomeIcon icon={faVenus} size="4x" />
                Girl
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="invalid-feedback">{this.state.errors.gender}</div>
          </div>
          <div className="card text-center box-shadow p-4">
            <h4>Weight</h4>
            <div className="weight-display mb-3">
              {this.state.units !== "metric"
                ? `${`${parseInt(this.state.weight / 16)} lbs, ${
                    this.state.weight % 16
                  } oz`}`
                : `${this.state.weight + " kg"}`}
            </div>
            <Form>
              <Form.Group controlId="formBasicRangeWeight">
                <Form.Control
                  type="range"
                  className="form-control-range"
                  size="lg"
                  min={
                    this.state.units !== "metric"
                      ? this.state.minWeightImperial
                      : this.state.minWeightMetric
                  }
                  max={
                    this.state.units !== "metric"
                      ? this.state.maxWeightImperial
                      : this.state.maxWeightMetric
                  }
                  step={this.state.units !== "metric" ? "1" : "0.05"}
                  defaultValue={
                    this.state.units !== "metric"
                      ? this.state.avgWeightImperial
                      : this.state.avgWeightMetric
                  }
                  onChange={this.onChangeWeight}
                />
              </Form.Group>
            </Form>
            {this.state.units === "both" && (
              <div className="weight-display">
                {(Math.ceil(this.state.weight / 35.274 / 0.05) * 0.05).toFixed(
                  2
                ) + " kg"}
              </div>
            )}
          </div>
          <div className="card text-center box-shadow p-4">
            <h4>Length</h4>
            <div className="length-display mb-3">
              {this.state.units !== "metric"
                ? `${this.state.length + '"'}`
                : `${this.state.length + " cm"}`}
            </div>
            <Form>
              <Form.Group controlId="formBasicRangeLength">
                <Form.Control
                  type="range"
                  className="form-control-range"
                  size="lg"
                  min={
                    this.state.units !== "metric"
                      ? this.state.minLengthImperial
                      : this.state.minLengthMetric
                  }
                  max={
                    this.state.units !== "metric"
                      ? this.state.maxLengthImperial
                      : this.state.maxLengthMetric
                  }
                  step={this.state.units !== "metric" ? "0.25" : "0.1"}
                  defaultValue={
                    this.state.units !== "metric"
                      ? this.state.avgLengthImperial
                      : this.state.avgLengthMetric
                  }
                  onChange={this.onChangeLength}
                />
              </Form.Group>
            </Form>
            {this.state.units === "both" && (
              <div className="length-display">
                {(Math.ceil((this.state.length * 2.54) / 0.5) * 0.5).toFixed(
                  1
                ) + " cm"}
              </div>
            )}
          </div>
          <div className="card text-center box-shadow p-4" id="datePickerContainer">
            <h4>Birth Date</h4>
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
              <DatePicker
                placeholder="Select the Birth Date"
                id="datePicker"
                className="xl-input xl-input-date"
                value={this.state.date}
                // minDate={new Date()}
                onChange={(value) => this.onChangeDate(value)}
                valueAlignment="center"
                formatStyle="medium"
                locale={this.state.locale.name}
                icon={<FontAwesomeIcon icon={faCalendarCheck} size="lg" />}
                required={true}
              />
              <div className="invalid-feedback">{this.state.errors.date}</div>
            </div>
          </div>
          <div className="card text-center box-shadow p-4" id="timePickerContainer">
            <h4>Birth Time</h4>
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
              <TimePicker
                id="timePicker"
                value={this.state.time}
                onChange={(value) => this.onChangeTime(value)}
                valueAlignment="center"
                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto xl-input xl-input-time"
                hour24={this.state.time_format === "24h" ? true : false}
                icon={<FontAwesomeIcon icon={faClock} />}
                required={true}
              />
              <div className="invalid-feedback">{this.state.errors.time}</div>
            </div>
          </div>
          <div className="alert-warning" role="alert" id="alert">
            {this.state.errors.editResults}
          </div>
          <div className="cancel-submit mt-4">
            <a href={`/pool/${pool_id}`} className="btn btn-outline-primary btn-lg px-4 mr-3">
              Cancel
            </a>
            <button
              type="submit"
              value="Post"
              className="btn btn-primary btn-lg flex-grow-1"
              onClick={this.setCanvas}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Update Results
            </button>
          </div>
        </div>
        <br />
        <br />
      </div>
      </>
    );
  }
}
export default EditResults;
