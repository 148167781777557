import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import { PostData } from "../../services/postData";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBaby, faSquarePollVertical, faListCheck, faAward, faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { images } from "../../constants";
import NewUsers from "./newUsers";
import NewPools from "./newPools";
import NewBabies from "./newBabies";
import "./dashboard.scss";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Dashboard",
        back: true,
        backLink: "/my-profile",
        menu: true,
        menuLink: "",
      },
      users: 0,
      pools: 0,
      betz: 0,
      results: 0,
      vip: 0,
      newUsers: '',
      newPools: '',
      newBabies: '',
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData")).userData.admin) {
      this.getUsersTotal();
      this.getPoolsTotal();
      this.getBetzTotal();
      this.getResultsTotal();
      this.getNewUsers();
      this.getNewPools();
      this.getNewBabies();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  getUsersTotal() {
    PostData("getDashboardUsersTotal", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.users) {
        this.setState({
          users: parseInt(responseJson.users.users),
          vip: parseInt(responseJson.users.vip)
        });
      } else alert(responseJson.error);
    });
  }

  getPoolsTotal() {
    PostData("getDashboardPoolsTotal", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.pools) {
        this.setState({
          pools: responseJson.pools
        });
      } else alert(responseJson.error);
    });
  }

  getBetzTotal() {
    PostData("getDashboardBetzTotal", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.betz) {
        this.setState({
          betz: responseJson.betz
        });
      } else alert(responseJson.error);
    });
  }

  getResultsTotal() {
    PostData("getDashboardResultsTotal", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.results) {
        this.setState({
          results: responseJson.results
        });
      } else alert(responseJson.error);
    });
  }

  getNewUsers() {
    PostData("getDashboardNewUsers", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.newUsers) {
        this.setState({ newUsers: responseJson.newUsers });
      } else {
        console.log('Fetching failed at getNewUsers()');
      }
    });
  }

  getNewPools() {
    PostData("getDashboardNewPools", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.newPools) {
        this.setState({ newPools: responseJson.newPools });
      } else {
        console.log('Fetching failed at getNewPools()');
      }
    });
  }

  getNewBabies() {
    PostData("getDashboardNewBabies", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.newBabies) {
        this.setState({ newBabies: responseJson.newBabies });
      } else {
        console.log('Fetching failed at getNewBabies()');
      }
    });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/signup"} />;
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="dashboard-top mt-4">
        <div className="card dashboard-top-card box-shadow">
          <div className="dashboard-icon"><FontAwesomeIcon icon={faUser} size="2x" /></div>
          <div className="dashboard-info">
            <h5 className="dashboard-info-title">Users</h5>
            <h3 className="dashboard-info-amount">
              <CountUp
                start={0}
                end={this.state.users}
                duration={2}
                key="users"
              ></CountUp>
            </h3>
          </div>
        </div>
        <div className="card dashboard-top-card box-shadow">
          <div className="dashboard-icon"><FontAwesomeIcon icon={faSquarePollVertical} size="2x" /></div>
          <div className="dashboard-info">
            <h5 className="dashboard-info-title">Pools</h5>
            <h3 className="dashboard-info-amount">
              <CountUp
                start={0}
                end={this.state.pools}
                duration={2}
                key="users"
              ></CountUp>
            </h3>
          </div>
        </div>
        <div className="card dashboard-top-card box-shadow">
          <div className="dashboard-icon"><FontAwesomeIcon icon={faListCheck} size="2x" /></div>
          <div className="dashboard-info">
            <h5 className="dashboard-info-title">Betz</h5>
            <h3 className="dashboard-info-amount">
              <CountUp
                start={0}
                end={this.state.betz}
                duration={2}
                key="users"
              ></CountUp>
            </h3>
          </div>
        </div>
        <div className="card dashboard-top-card box-shadow">
          <div className="dashboard-icon"><FontAwesomeIcon icon={faBaby} size="2x" /></div>
          <div className="dashboard-info">
            <h5 className="dashboard-info-title">Babies</h5>
            <h3 className="dashboard-info-amount">
              <CountUp
                start={0}
                end={this.state.results}
                duration={2}
                key="users"
              ></CountUp>
            </h3>
          </div>
        </div>
        <div className="card dashboard-top-card box-shadow">
          <div className="dashboard-icon"><FontAwesomeIcon icon={faAward} size="2x" /></div>
          <div className="dashboard-info">
            <h5 className="dashboard-info-title">VIP</h5>
            <h3 className="dashboard-info-amount">
              <CountUp
                start={0}
                end={this.state.vip}
                duration={2}
                key="users"
              ></CountUp>
            </h3>
          </div>
        </div>
      </div>
      <div className="dashboard-lists mb-4">
        <NewUsers
          newUsers={this.state.newUsers}
        />
        <NewPools
          newPools={this.state.newPools}
        />
        <NewBabies
          newBabies={this.state.newBabies}
        />
      </div>
      </>
    );
  }
}
export default Dashboard;
