import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import { PostData } from "../../services/postData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Application, Breadcrumb, Breadcrumbs, TableWithBrowserPagination, Column, Badge, Avatar } from 'react-rainbow-components';
import "./dashboard.scss";

const theme = {
    rainbow: {
        palette: {
            brand: '#00c7c0',
        },
    },
};
const StatusBadge = ({ value }) => value ? <Badge label='pending' variant='outline-brand' /> : <Badge label='verified' />;
const VipBadge = ({ value }) => value==='1'&&<Badge label={'VIP'} variant='brand' style={{'color': '#a67c00', 'backgroundColor': '#ffdc73', 'fontWeight': '600'}} />;
const UserAvatar = ({ value }) => <Avatar
  src={value}
  icon={<FontAwesomeIcon icon={faUser} />}
  size="medium"
  style={{'color': 'white'}}
  className="m-1 ml-2"
/>;
const Pools = ({ value }) => { return <span className={value === '0' && 'light-color'}>{value}</span> }

class AllUsers extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "All Users",
        back: true,
        backLink: "/dashboard",
        menu: true,
        menuLink: "",
      },
      activePage: 1,
      pageSize: 10,
      allUsers: [],
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData")).userData.admin) {
      this.getAllUsers();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  getAllUsers() {
    PostData("getDashboardAllUsers", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.allUsers) {
        this.setState({ allUsers: responseJson.allUsers });
      } else {
        console.log('Fetching failed at getAllUsers()');
      }
    });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/signup"} />;
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="boxed breadcrumbs mt-4">
        <Breadcrumbs>
            <Breadcrumb label="Dashboard" href="/dashboard" />
            <Breadcrumb label={this.state.navbar.name} />
        </Breadcrumbs>
      </div>
      <Application theme={theme}>
      <div className="dashboard-lists mb-4">
        <div className="card dashboard-list box-shadow py-5 px-0 mb-4 mt-1">
          <TableWithBrowserPagination
            showRowNumberColumn
            rowNumberOffset={this.state.activePage * this.state.pageSize - this.state.pageSize}
            pageSize={this.state.pageSize}
            data={this.state.allUsers}
            keyField="user_id"
            style={{width:'100%'}}>
          <Column defaultWidth={60} header="" field="image" component={UserAvatar} />
          <Column header="User" field="name" cellAlignment="left"/>
          <Column header="Email" field="email" />
          <Column defaultWidth={140} header="Status" field="activation_token_hash" headerAlignment="center" cellAlignment="center" component={StatusBadge} />
          <Column defaultWidth={220} header="User ID" field="user_id" />
          <Column defaultWidth={80} header="Pools" field="pools" headerAlignment="center" cellAlignment="center" component={Pools} />
          <Column defaultWidth={80} header={<FontAwesomeIcon icon={faAward} size="lg" />} field="vip" headerAlignment="center" cellAlignment="center" component={VipBadge} />
          </TableWithBrowserPagination>
        </div>
      </div>
      </Application>
      </>
    );
  }
}
export default AllUsers;
