import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import { Header } from "../../components";
import MobileHeader from "../mobileHeader/mobileHeader";
import AvatarEditor from "react-avatar-editor";
import bsCustomFileInput from "bs-custom-file-input";
//import { confirmAlert } from "react-confirm-alert";
//import "../../styles/react-confirm-alert.css";

class EditAvatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbar: {
        name: "Avatar",
        back: true,
        backLink: "/edit-profile",
        menu: false,
        menuLink: "",
      },
      userId: "",
      image: "",
      canvas: "",
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 100,
      preview: null,
      width: 400,
      height: 400,
      loading: false,
      errors: {},
      redirectToReferrer: false,
    };

    this.setCanvas = this.setCanvas.bind(this);
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      let data = JSON.parse(sessionStorage.getItem("userData"));
      let userId = data.userData.user_id;
      let image = data.userData.image;
      this.setState({
        userId: userId,
        image: image,
      });
      //this.getProfileDetails();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  componentDidMount() {
    bsCustomFileInput.init();
  }

  handleNewImage = (e) => {
    this.setState({ image: e.target.files[0] });
    document.getElementById("fileInputLabel").value = e.target.files[0];
  };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  setCanvas() {
    setTimeout(
      function () {
        document.getElementById(
          "hidden-data"
        ).value = this.editor.getImageScaledToCanvas().toDataURL();
      }.bind(this),
      50
    );
    setTimeout(
      function () {
        let canvas = document.getElementById("hidden-data").value;
        this.setState({ canvas }, () => this.onClickSave());
      }.bind(this),
      100
    );
  }

  setEditorRef = (editor) => (this.editor = editor);

  onClickSave = () => {
    console.log("error");
    if (this.editor) {
      console.log("Uploading image...");
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      //const canvas = this.editor.getImage().toDataURL();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      //const canvasScaled = this.editor.getImageScaledToCanvas().toDataURL();
      //console.log(canvasScaled);

      //this.setState({ canvas: canvasScaled });
      //let dataURL = canvasScaled.toDataURL();
      //console.log(dataURL);

      if (this.state.canvas) {
        let errors = {};
        this.setState({ loading: true });
        PostData("saveAvatar", this.state).then((result) => {
          let responseJson = result;
          this.setState({ message: "" });
          if (responseJson.userData) {
            sessionStorage.setItem("userData", JSON.stringify(responseJson));
            if (localStorage.getItem("userData")) {
              localStorage.setItem("userData", sessionStorage.getItem("userData"))
            }
            this.setState({ loading: false });
            this.setState({ redirectToReferrer: true });
          } else {
            //alert(result.error);
            errors["signup"] = result.error;
            document.getElementById("alert").classList.add("alert");
            this.setState({ loading: false });
            this.setState({
              errors: errors,
            });
          }
        });
      } else {
        this.setState({ message: "There is no image to save" });
      }
    }
  };

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/login"} />;
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="color"
      />
      <div className="my_pools-circle-lip my_pools-circle-lip-shortest box-shadow">
      </div>
      <div className="boxed mb-4" id="Body">
        <div className="backdrop box-shadow card mb-5">
          <div className="medium-12 text-center">
            <div>
              <textarea id="hidden-data" />
              <AvatarEditor
                ref={this.setEditorRef}
                scale={parseFloat(this.state.scale)}
                width={this.state.width}
                height={this.state.height}
                position={this.state.position}
                onPositionChange={this.handlePositionChange}
                rotate={parseFloat(this.state.rotate)}
                borderRadius={
                  this.state.width / (100 / this.state.borderRadius)
                }
                color={[255, 255, 255, 0.6]}
                image={
                  this.state.image
                    ? this.state.image
                    : "../assets/users/placeholder.gif"
                }
                className="editor-canvas"
              />
            </div>
            <div className="form-group">
              <label htmlFor="formControlRange">Zoom</label>
              <input
                type="range"
                className="form-control-range"
                name="scale"
                step="0.01"
                min={this.state.allowZoomOut ? "0.1" : "1"}
                max="2"
                defaultValue={parseFloat(this.state.scale)}
                onChange={this.handleScale}
              />
            </div>
            <br />
            <div className="custom-file">
              <input
                type="file"
                id="fileInput"
                className="custom-file-input"
                accept="image/*"
                onChange={this.handleNewImage}
              />
              <label
                className="custom-file-label"
                htmlFor="fileInput"
                id="fileInputLabel"
              >
                Add a New Image
              </label>
            </div>
            <br />
            <button
              type="button"
              className="btn btn-primary btn-lg mt-4 px-5"
              onClick={this.setCanvas}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Save
            </button>
            <div className="alert-warning" role="alert" id="alert">
              {this.state.errors.signup}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default EditAvatar;
