import React, { Component } from "react";
import { PostData } from "../../services/postData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faVenusMars, faScaleUnbalancedFlip, faArrowUpWideShort, faBaby } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import ReactApexChart from 'react-apexcharts'

import "./results.scss";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pool_id: "",
      name: "",
      gender: "",
      weight: "",
      length: "",
      date: "",
      time: "",
      winner: {
        name: "",
        relation: "",
        gender: "",
        gender_pts: "",
        weight: "",
        weight_pts: "",
        length: "",
        length_pts: "",
        date: "",
        date_pts: "",
        time: "",
        time_pts: "",
        total_pts: ""
      },
      series: [25, 30, 25, 10, 10],
      options: {
        chart: {
          width: 600,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: `"Poppins", sans-serif`,
                  fontSize: 24,
                  fontWeight: 400,
                  offsetY: 30
                },
                value: {
                  show: true,
                  fontFamily: `"Poppins", sans-serif`,
                  fontSize: 48,
                  fontWeight: 500,
                  color: ('color', '#747474'),
                  offsetY: -15
                },
                total: {
                  showAlways: false,
                  show: true,
                  label: "OUT OF 100",
                  fontFamily: `"Poppins", sans-serif`,
                  fontSize: 22,
                  color: ('color', '#747474'),
                }
              }
            }
          },
        },
        labels: ['Sex', 'Date', 'Time', 'Weight', 'Length'],
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient'
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              width: 480
            },
            legend: {
              position: 'bottom',
              fontFamily: `"Poppins", sans-serif`,
              fontSize: 15,
              labels:
                { colors: ('color', '#747474') },
              markers: {
                width: 14,
                height: 14
              }
            }
          }
        }]
      },
      redirectToReferrer: false
    }

    this.getResults = this.getResults.bind(this);
    this.formatFullDate = this.formatFullDate.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData")) {
      this.setPoolId();
    } else {
      this.setState({ redirectToReferrer: true });
      this.setPoolId();
    }
  }

  setPoolId() {
    this.setState({ pool_id: this.props.pool_id }, () => {
      this.getResults();
    });
  }

  getResults() {
    if (this.state.pool_id) {
      PostData("getResults", { pool_id: this.state.pool_id }).then((result) => {
        let responseJson = result;
        if (responseJson.poolResults) {
          // sessionStorage.setItem("poolResults", JSON.stringify(responseJson));
          this.setState({
            name: responseJson.poolResults.name,
            baby_img: responseJson.poolResults.baby_img,
            gender: responseJson.poolResults.gender,
            weight: responseJson.poolResults.weight,
            length: responseJson.poolResults.length,
            date: responseJson.poolResults.date,
            time: responseJson.poolResults.time,
            redirectToReferrer: true
          });
          this.getWinner();
        }
      });
    }
  }

  getWinner() {
    if (this.state.pool_id) {
      PostData("getWinner", { pool_id: this.state.pool_id }).then((result) => {
        let responseJson = result;
        if (responseJson.poolWinner) {
          // sessionStorage.setItem("poolWinner", JSON.stringify(responseJson));
          this.setState({
            winner: {
              name: responseJson.poolWinner.name,
              relation: responseJson.poolWinner.relation,
              gender: responseJson.poolWinner.gender,
              gender_pts: responseJson.poolWinner.gender_pts,
              weight: responseJson.poolWinner.weight,
              weight_pts: responseJson.poolWinner.weight_pts,
              length: responseJson.poolWinner.length,
              length_pts: responseJson.poolWinner.length_pts,
              date: responseJson.poolWinner.date,
              date_pts: responseJson.poolWinner.date_pts,
              time: responseJson.poolWinner.time,
              time_pts: responseJson.poolWinner.time_pts,
              total_pts: responseJson.poolWinner.total_pts
            },
            series: [parseFloat(responseJson.poolWinner.gender_pts),
              parseFloat(responseJson.poolWinner.date_pts),
              parseFloat(responseJson.poolWinner.time_pts),
              parseFloat(responseJson.poolWinner.weight_pts),
              parseFloat(responseJson.poolWinner.length_pts)]
          });
        } else {
          console.log("winner NOT fetched");
        }
      });
    }
  }

  formatFullDate(date) {
    const dateObject = new Date(new Date(date).getTime() + Math.abs(new Date(date).getTimezoneOffset()*60000));
    const formattedDate = dateObject.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
    return formattedDate;
  }

  formatDate(fetchDate) {
    let date = fetchDate.split("-");
    let yy = date[0];
    let mm = date[1];
    let dd = date[2];
    switch (this.props.date_format) {
      case "dd-mm-yyyy":
        return `${dd}/${mm}/${yy}`;
      case "mm-dd-yyyy":
        return `${mm}/${dd}/${yy}`;
      case "yyyy-mm-dd":
        return `${yy}/${mm}/${dd}`;
      default:
        return "No Date";
    }
  }

  formatTime(fetchTime) {
    let time = fetchTime.split(":");
    let hh = time[0];
    let mm = time[1];
    // let ss = time[2];
    let xx = "AM";
    switch (this.props.time_format) {
      case "12h":
        if (hh >= 12) {
          xx = "PM";
          if (hh > 12) {
            hh -= 12;
          }
        }
        if (hh === 0) {
          hh = 12;
        }
        return `${hh}:${mm} ${xx}`;
      case "24h":
        return `${hh}:${mm}`;
      default:
        return "No Time";
    }
  }

  formatPoints(points) {
    return parseFloat(points);
  }

  render() {
    return (
      <div className="app__results text-center">
        <div className="my_pools-circle-lip my_pools-circle-lip-long d-flex flex-column justify-content-between align-items-center">
          <h1 className="text-white pacifico-regular mt-1">Congrats!</h1>
          <div className="app__results-baby-img">
            <img src={this.state.baby_img} alt={this.state.name}></img>
            <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
              <defs>
                <symbol id="box">
                  <rect x="0" y="0" width="120" height="120" />
                </symbol>
                <symbol id="star">
                  <path d="M 0 0 C 40 40, 80 40, 120 0" />
                  <path d="M 0 0 C 40 40, 40 80, 0 120" />
                  <path d="M 120 0 C 80 40, 80 80, 120 120" />
                  <path d="M 0 120 C 40 80, 80 80, 120 120" />
                </symbol>
                <mask id="mask">
                  <rect x="0" y="0" width="120" height="120" fill="white" />
                  <path d="M 0 0 C 40 40, 80 40, 120 0" fill="black" />
                  <path d="M 0 0 C 40 40, 40 80, 0 120" fill="black" />
                  <path d="M 120 0 C 80 40, 80 80, 120 120" fill="black" />
                  <path d="M 0 120 C 40 80, 80 80, 120 120" fill="black" />
                </mask>
              </defs>
            </svg>
            <div className="stars">
              <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" className="star star-1">
                <use href="#box" mask="url(#mask)" fill="#ffffff"></use>
                <use href="#star" fill="none" stroke="white"></use>
              </svg>
              <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" className="star star-2">
                <use href="#box" mask="url(#mask)" fill="#ffffff"></use>
                <use href="#star" fill="none" stroke="white"></use>
              </svg>
              <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" className="star star-3">
                <use href="#box" mask="url(#mask)" fill="#ffffff"></use>
                <use href="#star" fill="none" stroke="white"></use>
              </svg>
              <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg" className="star star-4">
                <use href="#box" mask="url(#mask)" fill="#ffffff"></use>
                <use href="#star" fill="none" stroke="white"></use>
              </svg>
            </div>
          </div>
        </div>
        <div className="boxed">
          <div className="medium-12 columns">
            <div className="app__results-baby-info card box-shadow">
              <div className="app__results-baby-info-top">
                <div className={`baby-info-gender ${this.state.gender === "M" ? "baby-info-gender-boy" : this.state.gender === "F" ? "baby-info-gender-girl" : "baby-info-gender-neutral"}`}>
                <span>
              {this.state.gender === "M" ? (
                <FontAwesomeIcon
                  icon={faMars}
                  size="3x"
                  className="betz-gender-boy"
                />
              ) : this.state.gender === "F" ? (
                <FontAwesomeIcon
                  icon={faVenus}
                  size="3x"
                  className="betz-gender-girl"
                />
              ) : <FontAwesomeIcon icon={faVenusMars} size="3x"/>}
            </span>
                </div>
                <div className="baby-info-name">
                  <h5>Welcome to the world</h5>
                  <h2 className={this.state.gender === "M" ? "color-gender-boy" : "color-gender-girl"}>{this.state.name}</h2>
                  <p>Born {this.formatFullDate(this.state.date)}</p>
                </div>
              </div>
              <div className="app__results-baby-info-separator"></div>
              <div className="app__results-baby-info-bottom">
                <div className="baby-info-stat baby-info-weight">
                  <FontAwesomeIcon icon={faScaleUnbalancedFlip} size="xl" />
                  <h6>
                  {this.props.units === "metric"
                ? `${parseFloat(this.state.weight).toFixed(2) + " kg"}`
                : `${
                    parseInt(this.state.weight / 16) +
                    " lbs" +
                    ", " +
                    Math.round(this.state.weight % 16) +
                    " oz"
                  }`}
              {this.props.units === "both" && (
                <div className="metric-optional">
                  {"(" +
                    (Math.ceil(this.state.weight / 35.274 / 0.05) * 0.05).toFixed(2) +
                    " kg)"}
                </div>
                    )}
                  </h6>
                </div>
                <div className="baby-info-stat baby-info-length">
                  <FontAwesomeIcon icon={faArrowUpWideShort} size="xl" />
                  <h6>
                  {this.props.units === "metric"
                ? `${this.state.length ? (parseFloat(this.state.length).toFixed(1) + " cm") : "00.0" + " cm"}`
                : `${this.state.length ? (parseFloat(this.state.length).toFixed(2) + '"') : "00.0" + '"'}`}
              {this.props.units === "both" && (
                <div className="metric-optional">
                  {"(" +
                    ((Math.ceil(this.state.length * 2.54) / 0.5) * 0.5).toFixed(1) +
                    " cm)"}
                </div>
              )}
                  </h6>
                </div>
                <div className="baby-info-stat baby-info-time">
                  <FontAwesomeIcon icon={faClock} size="xl" />
                  <h6>
                  {this.state.time ? this.formatTime(this.state.time) : "00:00"}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {sessionStorage.getItem("userData") &&
          JSON.parse(sessionStorage.getItem("userData")).userData.user_id ===
          this.props.pool_owner && (
            <a href={`/pool/${this.props.pool_id}/edit-results`} className={`btn-addition box-shadow mb-2 ${this.state.gender === "M" ? "color-gender-boy" : "color-gender-girl"}`}><FontAwesomeIcon icon={faBaby} size="lg" className="icon-inline"/> Update Results</a>
            )}
        <p className="my-2">The winner is:</p>
        <h4 className="primary-color mb-0">{this.state.winner.name && this.state.winner.name}</h4>
        <p>with a score of <strong>{this.state.winner.total_pts && this.formatPoints(this.state.winner.total_pts)}</strong> out of 100!</p>

        <ReactApexChart options={this.state.options} series={this.state.series} className="donut-chart mb-3" type="donut" width={600} />
      </div>
    );
  }
}
export default Results;
