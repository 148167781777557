import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import AvatarEditor from "react-avatar-editor";
import bsCustomFileInput from "bs-custom-file-input";
import { Header } from "../../components";
import MobileHeader from "../mobileHeader/mobileHeader";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import { DatePicker } from "react-rainbow-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faCalendarXmark } from "@fortawesome/free-solid-svg-icons";
import "../../styles/custom.css";
import "./addPool.css";

const defaultOptions = ['email'];

class AddPool extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Create a Pool",
        back: true,
        backLink: "/my-profile",
        menu: true,
        menuLink: "",
      },
      pool_title: "",
      pool_desc: "",
      pool_img: "",
      date_format: "mm-dd-yyyy",
      due_date: "",
      limit_date: "",
      locale: { name: "en-US", label: "English (US)" },
      time_format: "12h",
      units: "imperial",
      theme: "",
      color: "",
      canvas: "",
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1.125,
      rotate: 0,
      borderRadius: 100,
      preview: null,
      width: 400,
      height: 400,
      redirectToReferrer: false,
      message: "",
      loading: "",
      errors: {},
    };

    this.addPool = this.addPool.bind(this);
    this.submitPool = this.submitPool.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDesc = this.onChangeDesc.bind(this);
    this.onChangeTimeFormat = this.onChangeTimeFormat.bind(this);
    this.onChangeDateFormat = this.onChangeDateFormat.bind(this);
    this.onChangeDueDate = this.onChangeDueDate.bind(this);
    this.onChangeUnits = this.onChangeUnits.bind(this);
  }

  componentWillMount() {
    if (!sessionStorage.getItem("userData")) {
      this.setState({ redirectToReferrer: true });
    }
  }

  componentDidMount() {
    bsCustomFileInput.init();
  }

  addPool(e) {
    e.preventDefault();
    this.setState({ loading: true });
    setTimeout(
      function () {
        document.getElementById(
          "hidden-data"
        ).value = this.editor.getImageScaledToCanvas().toDataURL();
      }.bind(this),
      50
    );
    setTimeout(
      function () {
        let canvas = document.getElementById("hidden-data").value;
        this.setState({ canvas }, () => this.submitPool());
      }.bind(this),
      100
    );
  }

  submitPool() {
    let errors = {};
    let data = JSON.parse(sessionStorage.getItem("userData"));
    let postData = {
      user_id: data.userData.user_id,
      pool_title: this.state.pool_title,
      pool_desc: this.state.pool_desc,
      pool_img: this.state.canvas,
      date_format: this.state.date_format,
      due_date: this.state.due_date,
      time_format: this.state.time_format,
      units: this.state.units,
      limit_date: this.state.limit_date,
      theme: this.state.theme,
      color: this.state.color,
    };
    if (this.validate(postData)) {
      PostData("addPool", postData).then((result) => {
        let responseJson = result;
        this.setState({ data: responseJson.newPoolData });
        if (responseJson.newPoolData) {
          this.setState({ pool_id: responseJson.newPoolData.pool_id });
          sessionStorage.setItem("newPoolData", JSON.stringify(responseJson));
          this.setState({ loading: false });
          // this.setState({ redirectToReferrer: true });
        } else {
          errors["addPool"] = result.error;
          document.getElementById("alert").classList.add("alert");
          this.setState({ loading: false });
          this.setState({
            errors: errors,
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.limit_date === "") {
      isValid = false;
      errors["limit_date"] = "Limit date is required.";
      document.getElementById("limitDatePicker").classList.add("is-invalid");
    } else {
      document.getElementById("limitDatePicker").classList.remove("is-invalid");
    }

    if (this.state.due_date === "") {
      isValid = false;
      errors["due_date"] = "Due date is required.";
      document.getElementById("dueDatePicker").classList.add("is-invalid");
    } else {
      document.getElementById("dueDatePicker").classList.remove("is-invalid");
    }

    if (this.state.pool_desc === "") {
      isValid = false;
      errors["pool_desc"] = "Pool description is required.";
      document.getElementById("inputDesc").classList.add("is-invalid");
      document.getElementById("inputDescContainer").scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    } else {
      document.getElementById("inputDesc").classList.remove("is-invalid");
    }

    if (this.state.pool_title === "") {
      isValid = false;
      errors["pool_title"] = "Pool title is required.";
      document.getElementById("inputTitle").classList.add("is-invalid");
      document.getElementById("inputTitleContainer").scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    } else {
      document.getElementById("inputTitle").classList.remove("is-invalid");
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeTitle(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      let error = "";

      if (this.state.pool_title === "") {
        error = "Pool title is required.";
        document.getElementById("inputTitle").classList.add("is-invalid");
      } else {
        document.getElementById("inputTitle").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.pool_title = error;
        return { errors };
      });
    });
  }

  onChangeDesc(e) {
    let countDesc = document.getElementById("countDesc");
    countDesc.innerHTML = e.target.value.length + " / 250";

    this.setState({ [e.target.name]: e.target.value }, () => {
      let error = "";

      if (this.state.pool_desc === "") {
        error = "Pool description is required.";
        document.getElementById("inputDesc").classList.add("is-invalid");
      } else {
        document.getElementById("inputDesc").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.pool_desc = error;
        return { errors };
      });
    });
  }

  handleNewImage = (e) => {
    this.setState({ pool_img: e.target.files[0] });
    document.getElementById("fileInputLabel").value = e.target.files[0];
  };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ pool_img: reader.result });
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      document.getElementById("img-holder").classList.add("image-selected");
    }
  };

  onChangeTimeFormat(value) {
    this.setState({ time_format: value });
  }

  onChangeDateFormat(value) {
    this.setState({ date_format: value });
  }

  onChangeUnits(value) {
    this.setState({ units: value });
  }

  onChangeDueDate(value) {
    this.setState({ due_date: value }, () => {
      let error = "";

      if (this.state.due_date === "") {
        error = "Due date is required.";
        document.getElementById("dueDatePicker").classList.add("is-invalid");
      } else {
        document.getElementById("dueDatePicker").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.due_date = error;
        return { errors };
      });
    });
  }

  onChangeLimitDate(value) {
    this.setState({ limit_date: value }, () => {
      let error = "";

      if (this.state.limit_date === "") {
        error = "Limit date is required.";
        document.getElementById("limitDatePicker").classList.add("is-invalid");
      } else {
        document.getElementById("limitDatePicker").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.limit_date = error;
        return { errors };
      });
    });
  }

  setEditorRef = (editor) => (this.editor = editor);

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/login"} />;
    }
    if (sessionStorage.getItem("newPoolData")) {
      return <Redirect to={`/pool/${this.state.pool_id}`} />;
    }
    const { pool_img } = this.state;
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="white"
        />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed" id="Body">
        <div className="backdrop box-shadow mb-5">
          <div className="medium-12 columns">
            <div className="form-label-group signup-label" id="inputTitleContainer">
              <input
                type="text"
                name="pool_title"
                id="inputTitle"
                placeholder="Pool Title"
                required
                onChange={this.onChangeTitle}
              />
              <label htmlFor="inputTitle">Pool Title</label>
              <div className="invalid-feedback">
                {this.state.errors.pool_title}
              </div>
            </div>
            <div className="form-label-group signup-label" id="inputDescContainer">
              <textarea
                name="pool_desc"
                id="inputDesc"
                placeholder="Pool Description"
                rows="3"
                maxLength="250"
                required
                onChange={this.onChangeDesc}
              />
              <span className="badge badge-secondary" id="countDesc">
                0 / 250
              </span>
              <label htmlFor="inputDesc">Pool Description</label>
              <div className="invalid-feedback">
                {this.state.errors.pool_desc}
              </div>
            </div>
            Pool Image
            <br />
            <textarea id="hidden-data" />
            <div className="text-center">
              <AvatarEditor
                ref={this.setEditorRef}
                scale={parseFloat(this.state.scale)}
                width={this.state.width}
                height={this.state.height}
                position={this.state.position}
                onPositionChange={this.handlePositionChange}
                rotate={parseFloat(this.state.rotate)}
                borderRadius={
                  this.state.width / (100 / this.state.borderRadius)
                }
                color={[255, 255, 255, 0.6]}
                image={
                  this.state.pool_img
                    ? this.state.pool_img
                    : "/assets/pools/placeholder.jpg"
                }
                className="editor-canvas"
              />
            </div>
            <div className="form-group">
              <label htmlFor="formControlRange">Zoom</label>
              <input
                type="range"
                className="form-control-range"
                name="scale"
                step="0.01"
                min={this.state.allowZoomOut ? "0.1" : "1"}
                max="2"
                defaultValue={parseFloat(this.state.scale)}
                onChange={this.handleScale}
              />
            </div>
            <br />
            <div className="custom-file">
              <input
                type="file"
                id="fileInput"
                className="custom-file-input"
                accept="image/*"
                onChange={this.handleNewImage}
              />
              <label
                className="custom-file-label"
                htmlFor="fileInput"
                id="fileInputLabel"
              >
                Add a New Image
              </label>
            </div>
            <label htmlFor="date_format">Date Format</label>
            <ToggleButtonGroup
              type="radio"
              name="date_format"
              defaultValue={this.state.date_format}
              onChange={this.onChangeDateFormat}
            >
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"mm-dd-yyyy"}
              >
                MM / DD / YYYY
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"dd-mm-yyyy"}
              >
                DD / MM / YYYY
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"yyyy-mm-dd"}
              >
                YYYY / MM / DD
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <br />
            <label htmlFor="due_date">Predicted Due Date</label>
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
              <DatePicker
                placeholder="Select a Due Date"
                id="dueDatePicker"
                value={this.state.due_date}
                minDate={new Date()}
                onChange={(value) => this.onChangeDueDate(value)}
                // label="Due Date"
                valueAlignment="center"
                formatStyle="medium"
                locale={this.state.locale.name}
                icon={<FontAwesomeIcon icon={faCalendarDay} size="lg" />}
                required={true}
              />
              <div className="invalid-feedback">
                {this.state.errors.due_date}
              </div>
            </div>
            <br />
            <label htmlFor="time_format">Time Format</label>
            <ToggleButtonGroup
              type="radio"
              name="time_format"
              defaultValue={this.state.time_format}
              onChange={this.onChangeTimeFormat}
            >
              <ToggleButton variant="outline-secondary" size="lg" value={"12h"}>
                12-hour (2 PM)
              </ToggleButton>
              <ToggleButton variant="outline-secondary" size="lg" value={"24h"}>
                24-hour (14:00)
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <br />
            <label htmlFor="units">Units of Measurement</label>
            <ToggleButtonGroup
              type="radio"
              name="units"
              defaultValue={this.state.units}
              onChange={this.onChangeUnits}
            >
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"imperial"}
              >
                Imperial
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"both"}
              >
                Both
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"metric"}
              >
                Metric
              </ToggleButton>
            </ToggleButtonGroup>
            <br /> 
            <br />
            <label htmlFor="limit_date">Accept Betz Until</label>
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
              <DatePicker
                placeholder="Select a Limit Date"
                id="limitDatePicker"
                value={this.state.limit_date}
                minDate={new Date()}
                maxDate={this.state.due_date}
                onChange={(value) => this.onChangeLimitDate(value)}
                // label="Due Date"
                valueAlignment="center"
                formatStyle="medium"
                locale={this.state.locale.name}
                icon={<FontAwesomeIcon icon={faCalendarXmark} size="lg" />}
                required={true}
              />
              <div className="invalid-feedback">
                {this.state.errors.limit_date}
              </div>
            </div>
            <br />
            <div className="alert-warning" role="alert" id="alert">
              {this.state.errors.addPool}
            </div>
            <div className="cancel-submit mt-4">
              <a
                href="/my-profile"
                className="btn btn-outline-primary btn-lg px-4 mr-3"
              >
                Cancel
              </a>
              <button
                type="submit"
                value="Post"
                className="btn btn-primary btn-lg flex-grow-1"
                onClick={this.addPool}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  style={{
                    display: this.state.loading ? "inline-block" : "none",
                  }}
                  role="status"
                  aria-hidden="true"
                ></span>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default AddPool;
