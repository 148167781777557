import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Header } from "../../components";
import { PostData } from "../../services/postData";
import PoolFeed from "../poolFeed/poolFeed";
import MobileHeader from "../mobileHeader/mobileHeader";
import { confirmAlert } from "react-confirm-alert";
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { images } from "../../constants";
import "../../styles/react-confirm-alert.css";
import "./home.scss";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbar: {
        name: "My Profile",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      data: [],
      pools: [],
      //userFeed: "",
      poolFeed: "",
      redirectToReferrer: false,
      name: "",
      image: "",
      vipAlert: "",
      vip: ""
    };

    this.getPoolFeed = this.getPoolFeed.bind(this);
    this.deletePool = this.deletePool.bind(this);
    this.deletePoolAction = this.deletePoolAction.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    // sessionStorage.removeItem("newPoolData");
    // sessionStorage.removeItem("poolData");
    // sessionStorage.removeItem("poolUnits");
    if (localStorage.getItem("userData")) {
      sessionStorage.setItem("userData", localStorage.getItem("userData"))
    }
    if (sessionStorage.getItem("userData")) {
      //this.getUserFeed();
      this.getPoolFeed();
    } else {
      this.setState({ redirectToReferrer: true });
    }
    if (this.props.location.state) {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      userData.userData.vip = "1";
      sessionStorage.setItem("userData", JSON.stringify(userData));

      this.setState({
        vip: this.props.location.state.vip,
        vipAlert: this.props.location.state.vipAlert
      });
    }
  }

  deletePool(e) {
    let pool_id = e.target.getAttribute("pools");
    let updateIndex = e.target.getAttribute("value");

    confirmAlert({
      title: "Delete Pool",
      message: "Are you sure you want to delete this pool?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deletePoolAction(pool_id, updateIndex),
        },
        {
          label: "No",
          onClick: () => alert("Click No"),
        },
      ],
    });
  }

  deletePoolAction(pool_id, updateIndex) {
    //let pool_id = document.getElementById("del").getAttribute("pools");

    let pools = JSON.parse(sessionStorage.getItem("userData"));

    let postData = { user_id: pools.userData.user_id, pool_id: pool_id };
    if (postData) {
      PostData("poolDelete", postData).then((result) => {
        this.state.pools.splice(updateIndex, 1);
        this.setState({ pools: this.state.pools });

        if (result.success) {
          alert(result.success);
        } else alert(result.error);
      });
    }
  }

  getPoolFeed() {
    let pools = JSON.parse(sessionStorage.getItem("userData"));
    this.setState({ name: pools.userData.name, image: pools.userData.image, vip: pools.userData.vip, admin: pools.userData.admin });
    let postData = { user_id: pools.userData.user_id };
    if (pools) {
      PostData("pools", postData).then((result) => {
        let responseJson = result;
        if (responseJson.poolData) {
          this.setState({ pools: responseJson.poolData });
        }
      });
    }
  }

  logout() {
    sessionStorage.setItem("userData", "");
    sessionStorage.clear();
    this.setState({ redirectToReferrer: true });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/signup"} />;
    }

    return (
      // <div className="medium-theme" id="Body">
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="color"
        logout={this.logout}
        redirectToReferrer={this.state.redirectToReferrer}
        />
        {this.state.admin && <a href="/dashboard" className="admin-dashboard-btn"><FontAwesomeIcon icon={faChartLine} size="lg" /></a>}
        <div className="boxed px-3 mb-0" id="vip_alert">
        {this.state.vipAlert !== "" && 
          <Alert variant="light" onClose={() => document.getElementById("vip_alert").remove()} dismissible>
            <Alert.Heading>Welcome to VIP!</Alert.Heading>
            <p>
              Enjoy UNLIMITED amount of betz on all your pools.
            </p>
          </Alert>
        }</div>
      {/* <div className="boxed px-3" id="vip-alert">
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading">Well done!</h4>
          <p>Aww yeah, you successfully read this important alert message. This example text is going to run a bit longer so that you can see how spacing within an alert works with this kind of content.</p>
          <hr/>
          <p className="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p>
        </div>
        <div className="alert alert-primary d-flex align-items-center alert-dismissible fade show" role="alert">
          <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
          </svg>
          <div>
            <strong>Welcome to VIP!</strong> Enjoy unlimited amount of betz on all your pools.
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </div> */}
      <div className="my_pools-circle-lip box-shadow mb-4">
        <div className="my_pools-profile">
          <div className="my_pools-avatar">
            <a href="/edit-profile">
              <img
                src={
                  this.state.image
                    ? this.state.image
                    : "../assets/users/placeholder.gif"
                }
                alt={this.state.name}
              />
            </a>
            <a href="/edit-profile" className="my_pools-avatar-edit-btn">
              <FontAwesomeIcon icon={faPen} size="sm" />
            </a>
            {this.state.vip === "1" && <img src={ images.vip_badge } className="my_pools-vip-badge" alt="vip badge" />}
          </div>
          <div className="my_pools-name text-center">
            <h3>{this.state.name}</h3>
          </div>
          <div className="my_pools-add-pool">
            <a href="/add-pool">
              <FontAwesomeIcon
                icon={["far", "calendar-plus"]}
                size="lg"
                className="icon-inline"
              />{" "}
              Add a New Pool
            </a>
          </div>
        </div>
      </div>
      <div className="boxed" id="Body">
        <PoolFeed
          poolData={this.state.pools}
          deletePool={this.deletePool}
          name={this.state.name}
        />
        </div>
      </>
    );
  }
}

export default Home;
