import React, { Component } from "react";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "../../components";
import { images } from "../../constants";
import "./404.scss";

class NotFound extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Ooops!",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      redirectToReferrer: false,
    };
  }

  render() {
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="white"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="app__404">
        <div className="app__404-glyph">
            <h2>4</h2>
            <img className="app__404-moon" src={ images.not_found_moon } />
            <h2>4</h2>
          </div>
          <h3>Page Not Found</h3>
          <p>Sorry, we couldn't find the page you're looking for.</p>
          <a className="btn btn-outline-primary mt-2 px-4 mb-5" href="/">Back to Home</a>
      </div>
      </>
    );
  }
}
export default NotFound;
