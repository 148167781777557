import React, { Component } from "react";
import { PostData } from "../../services/postData";
import { Redirect } from "react-router-dom";
import { Header } from "..";
import MobileHeader from "../mobileHeader/mobileHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "../../styles/floating-labels.css";
import "../../styles/custom.css";

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: {
        name: "Reset Password",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      type1: "password",
      icon1: faEyeSlash,
      type2: "password",
      icon2: faEyeSlash,
      redirectToReferrer: false,
      message: "",
      loading: "",
      errors: {},
    };
    this.getToken = this.getToken.bind(this);
    this.update = this.update.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.handleToggle1 = this.handleToggle1.bind(this);
    this.handleToggle2 = this.handleToggle2.bind(this);
  }

  componentDidMount() {
    console.log(this.props.match.params.token);
    this.getToken(this.props.match.params.token);
  }
  getToken(token) {
    PostData("getToken", {token: token}).then((result) => {
      let responseJson = result;
      console.log("responseJson: " + responseJson);
      if (responseJson.token) {
        this.setState({
          user_id: responseJson.token.user_id,
          name: responseJson.token.name,
          email: responseJson.token.email,
        });
      } else { // expired token
        this.setState({ redirectToReferrer: true });
      }
    });
  }

  update() {
    document.getElementById("alert").classList.remove("alert");
    if (this.validate()) {
      console.log(this.state);
      if (
        this.state.password &&
        this.state.passwordConfirm
      ) {
        if (this.state.password === this.state.passwordConfirm) {
          let errors = {};
          this.setState({ loading: true });
          PostData("update", this.state).then((result) => {
            let responseJson = result;
            console.log("responseJson: " + JSON.stringify(responseJson));
            this.setState({ message: "" });
            if (responseJson.userData) {
              sessionStorage.setItem("userData", JSON.stringify(responseJson));
              this.setState({ loading: false });
              // this.setState({ redirectToReferrer: true });
            } else {
              //alert(result.error);
              console.log(JSON.stringify(responseJson));
              errors["reset"] = result.error;
              document.getElementById("alert").classList.add("alert");
              this.setState({ loading: false });
              this.setState({
                errors: errors,
              });
            }
          });
        } else {
          this.setState({ message: "The passwords don't match." });
        }
      } else {
        this.setState({ message: "Please fill out all fields." });
      }
    }
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.password === "") {
      isValid = false;
      errors["password"] = "Please enter a password.";
      document.getElementById("inputPassword").classList.add("is-invalid");
    } else if (this.state.password.length < 8) {
      isValid = false;
      errors["password"] = "The password has to be minimum 8 characters.";
      document.getElementById("inputPassword").classList.add("is-invalid");
    } else {
      document.getElementById("inputPassword").classList.remove("is-invalid");
    }

    if (this.state.passwordConfirm === "") {
      isValid = false;
      errors["passwordConfirm"] = "Please enter a password.";
      document
        .getElementById("inputPasswordConfirm")
        .classList.add("is-invalid");
    } else if (this.state.password !== this.state.passwordConfirm) {
      isValid = false;
      errors["passwordConfirm"] = "The passwords don't match.";
      document
        .getElementById("inputPasswordConfirm")
        .classList.add("is-invalid");
    } else {
      document
        .getElementById("inputPasswordConfirm")
        .classList.remove("is-invalid");
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value }, () => {
      let error = "";

      if (this.state.password === "") {
        error = "Please enter a password.";
        document.getElementById("inputPassword").classList.add("is-invalid");
      } else if (this.state.password.length < 8) {
        error = "The password has to be minimum 8 characters.";
        document.getElementById("inputPassword").classList.add("is-invalid");
      } else {
        document.getElementById("inputPassword").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.password = error;
        return { errors };
      });
    });
  }

  onChangePasswordConfirm(e) {
    this.setState({ passwordConfirm: e.target.value }, () => {
      let error = "";

      if (this.state.passwordConfirm === "") {
        error = "Please enter a password.";
        document
          .getElementById("inputPasswordConfirm")
          .classList.add("is-invalid");
      } else if (this.state.password !== this.state.passwordConfirm) {
        error = "The passwords don't match.";
        document
          .getElementById("inputPasswordConfirm")
          .classList.add("is-invalid");
      } else {
        document
          .getElementById("inputPasswordConfirm")
          .classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.passwordConfirm = error;
        return { errors };
      });
    });
  }

  handleToggle1() {
    if (this.state.type1 === 'password') {
       this.setState({ icon1: faEye});
       this.setState({ type1: "text"});
    } else {
      this.setState({ icon1: faEyeSlash});
       this.setState({ type1: "password"});
    }
 }

 handleToggle2() {
   if (this.state.type2 === 'password') {
      this.setState({ icon2: faEye});
      this.setState({ type2: "text"});
   } else {
      this.setState({ icon2: faEyeSlash});
      this.setState({ type2: "password"});
   }
}

  render() {
    if (sessionStorage.getItem("userData")) {
      return <Redirect to={"/my-profile"} />;
    };
    if (this.state.redirectToReferrer) {
      return <Redirect
        to={{
        pathname: "/forgot-password",
        state: { error: "This is an invalid token or it already expired." }
      }}
    />;
    };
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="white"
        redirectToReferrer={!this.state.redirectToReferrer}
      />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed mb-4" id="sBody">
        <div className="backdrop box-shadow mb-4">
          <div className="medium-12 columns left">
            <h6 className="center mt-4 mb-4">Please choose a new password for your account</h6>
            <div className="form-label-group signup-label">
              <input
                type="text"
                name="name"
                id="inputName"
                className="form-control"
                placeholder="Name"
                value={this.state.name}
                disabled
                autoFocus=""
                onChange={this.onChangeName}
              />
              <label htmlFor="inputName">Name</label>
              <div className="invalid-feedback">{this.state.errors.name}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control"
                placeholder="Email"
                value={this.state.email}
                disabled
                autoFocus=""
                onChange={this.onChangeEmail}
              />
              <label htmlFor="inputEmail">Email</label>
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type={this.state.type1}
                name="password"
                id="inputPassword"
                className="form-control"
                placeholder="Password"
                required=""
                autoFocus=""
                onChange={this.onChangePassword}
              />
              <label htmlFor="inputPassword">Password</label>
              <span className="form-password-show-hide" onClick={this.handleToggle1}>
                <FontAwesomeIcon icon={this.state.icon1} />
              </span>
              <div className="invalid-feedback">
                {this.state.errors.password}
              </div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type={this.state.type2}
                name="passwordConfirm"
                id="inputPasswordConfirm"
                className="form-control"
                placeholder="Confirm Password"
                required=""
                autoFocus=""
                onChange={this.onChangePasswordConfirm}
              />
              <label htmlFor="inputPasswordConfirm">Confirm Password</label>
              <span className="form-password-show-hide" onClick={this.handleToggle2}>
                <FontAwesomeIcon icon={this.state.icon2} />
              </span>
              <div className="invalid-feedback">
                {this.state.errors.passwordConfirm}
              </div>
            </div>
            <div className="alert-danger" role="alert" id="alert">
              {this.state.errors.reset}
            </div>
            <button
              className="btn btn-lg btn-primary btn-block my-4"
              type="submit"
              onClick={this.update}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Update Password
            </button>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default Reset;
