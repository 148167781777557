import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentSms, faListCheck, faShare } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faCopy } from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faXTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMars, faVenus, faVenusMars, faSlidersH, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "./betzFeed.scss";
// import PoolInfo from "../poolInfo/poolInfo";
library.add(far);

class BetzFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pool_id: "",
      website: "app.squareseed.co",
      share_msg: ""
    }

    this.sharePool = this.sharePool.bind(this);
  }

  formatDate(fetchDate) {
    let date = fetchDate.split("-");
    let yy = date[0];
    let mm = date[1];
    let dd = date[2];
    switch (this.props.date_format) {
      case "dd-mm-yyyy":
        return `${dd}/${mm}/${yy}`;
      case "mm-dd-yyyy":
        return `${mm}/${dd}/${yy}`;
      case "yyyy-mm-dd":
        return `${yy}/${mm}/${dd}`;
    }
  }

  formatTime(fetchTime) {
    let time = fetchTime.split(":");
    let hh = time[0];
    let mm = time[1];
    let ss = time[2];
    let xx = "AM";
    switch (this.props.time_format) {
      case "12h":
        if (hh >= 12) {
          xx = "PM";
          if (hh > 12) {
            hh -= 12;
          }
        }
        if (hh == 0) {
          hh = 12;
        }
        return `${hh}:${mm} ${xx}`;
      case "24h":
        return `${hh}:${mm}`;
    }
  }

  formatPoints(points) {
    return parseFloat(points).toFixed(2);
  }

  toggleCard(e) {
    let id = e.target.id.slice(7); // Cutting off the first 7 chanracters of the id: betzBtn# leaving just the #
    let card = document.getElementById("betz" + id);
    if (card.classList.contains("mini")) {
      card.classList.remove("mini");
    } else {
      card.classList.add("mini");
    }
  }

  toggleAll() {
    let betzCards = document.getElementById("betzCards");
    if (betzCards.classList.contains("mini-all")) {
      betzCards.classList.remove("mini-all");
      for (let i = 0; i < betzCards.children.length; i++) {
        betzCards.children[i].classList.remove("mini");
      }
    } else {
      betzCards.classList.add("mini-all");
      for (let i = 0; i < betzCards.children.length; i++) {
        betzCards.children[i].classList.add("mini");
      }
    }
  }

  sharePool() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="share-pool-popup">
            <h2 className="mb-4">Share this pool?</h2>
            <p>Invite friends and family to join the fun using social media and/or email.</p>
            <div className="share-social-icons">
              <a className="share-social-icon facebook" title="Share on Facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${this.state.website}/pool/${this.props.pool_id}`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a className="share-social-icon twitter" href={`http://twitter.com/share?url=https://${this.state.website}/pool/${this.props.pool_id}&text=My%20baby%20pool%20is%20ready%20for%20your%20predictions!%20Place%20your%20bet%20on%20when%20the%20baby%20will%20arrive`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faXTwitter} /></a>
              <a className="share-social-icon whatsup" href={`https://wa.me/?text=https%3A%2F%2F${this.state.website}%2Fpool%2F${this.props.pool_id}`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faWhatsapp} /></a>
              <a className="share-social-icon email" href={`mailto:?body=The%20${this.props.pool_title}%20baby%20pool%20is%20ready%20for%20your%20predictions%21%20Place%20your%20bet%20on%20when%20the%20baby%20will%20arrive.%0A%0Ahttps%3A%2F%2F${this.state.website}%2Fpool%2F${this.props.pool_id}&subject=Place%20your%20bets%20in%20the%20${this.props.pool_title}%21`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faEnvelope} /></a>
              <a className="share-social-icon sms" href={`sms:?&body=Place%20your%20bets%20in%20the%20${this.props.pool_title}%21%0A%0AThe%20${this.props.pool_title}%20baby%20pool%20is%20ready%20for%20your%20predictions%21%20Place%20your%20bet%20on%20when%20the%20baby%20will%20arrive.%0A%0Ahttps%3A%2F%2F${this.state.website}%2Fpool%2F${this.props.pool_id}`} onClick={() => { onClose(); return false }} target="_blank"><FontAwesomeIcon icon={faCommentSms} /></a>
              <button className="share-social-icon clipboard" onClick={() => { this.copy(); onClose() }}><FontAwesomeIcon icon={faCopy}/></button>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    if (this.props.allBetz.length === 0 && sessionStorage.getItem("userData") &&
    JSON.parse(sessionStorage.getItem("userData")).userData.user_id == this.props.pool_owner) {
      return (
        <>
        <div className="betz-card box-shadow text-center mt-3">
          <div className="betz-card-content flex-column card-text text-center mt-2 mb-1">
            <h3>No betz yet?!</h3>
            <p style={{ width: "100%" }}>
              Try sharing your pool.
              <br />
              Once you receive some betz, they will appear here.
            </p>
          </div>
          <button
              onClick={this.sharePool}
              className="betz_feed-share-btn btn-wide"
            >
            Share Pool <FontAwesomeIcon icon={faShare} />
          </button>
        </div>
          {JSON.parse(sessionStorage.getItem("userData")).userData.vip !== "1" &&
          <div className='betz-card box-shadow mb-5'>
            <div className="boxed">
              <div className="betz-card-header">
                <div className="betz-info">
                  <h6>FREE tier</h6>
                  <p>Up to 5 betz</p>
                </div>
                <span>
                  <FontAwesomeIcon
                    icon={faVenusMars}
                    size="lg"
                    className="betz-gender-neutral"
                  />
                </span>
              </div>
              <div className="betz-card-content d-block">
                Unlock UNLIMITED betz for only <h4 className="primary-color ml-1 d-inline">$1</h4><br/>with Baby Betz VIP!
              </div>
              <div className="text-center mt-3 mb-1">
                <a href={`/unlock`} className="btn btn-lg btn-primary px-5">Unlock Now <FontAwesomeIcon icon={faCartShopping} size="lg" /></a>
              </div>
            </div>
          </div>
          }
        </>
      );
    } else if (this.props.allBetz.length === 0) {
      return (
        <>
        <div className="betz-card box-shadow text-center mt-3 mb-5">
          <div className="boxed">
            <div className="betz-card-content flex-column card-text text-center mt-2 mb-1">
              <h3>No betz yet?!</h3>
              <p style={{ width: "100%" }}>
                Be the first one to make a bet.
              </p>
            </div>
            <a href={`/pool/${this.props.pool_id}/place-betz`} className="betz_feed-place-betz-btn btn-wide">
              <FontAwesomeIcon icon={faListCheck} size="lg" className="icon-inline"/> PLACE YOUR BETZ
            </a>
          </div>
        </div>
        </>
      );
    }
    let betzFeed = this.props.allBetz.map(function (betz, index) {
      return (
        <div
          className={`betz-card box-shadow${sessionStorage.getItem("userData") &&
          JSON.parse(sessionStorage.getItem("userData")).userData.email ===
            betz.email ? ' bet-owner' : '' }`}
          key={index}
          id={`betz${index + 1}`}
        >
          <div className="boxed">
          <div className="betz-card-header">
            <div className="betz-info">
              <h6>{betz.name}</h6>
              <p>{betz.relation}</p>
            </div>
            <span>
              {betz.gender == "M" ? (
                <FontAwesomeIcon
                  icon={faMars}
                  size="lg"
                  className="betz-gender-boy"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faVenus}
                  size="lg"
                  className="betz-gender-girl"
                />
              )}
            </span>
            <div className="betz-edit-collapse">
              {sessionStorage.getItem("userData") &&
                JSON.parse(sessionStorage.getItem("userData")).userData
                  .user_id == this.props.pool_owner && (
                  <a
                    href={`/pool/${betz.pool_id}/betz/${betz.bet_id}/edit-betz`}
                    className="btn btn-outline-secondary btn-sm"
                  >
                    <FontAwesomeIcon icon={faSlidersH} size="sm" />
                  </a>
                )}
              {/* <button
                className="btn btn-outline-secondary btn-sm"
                id={`betzBtn${index + 1}`}
                onClick={this.toggleCard}
              >
                <FontAwesomeIcon icon={faChevronUp} size="sm" />
              </button> */}
              
              {betz.total_pts !== null &&
                <div className="total-points">
                  <h6>{this.formatPoints(betz.total_pts)}</h6>
                  <p>Points</p>
                </div>
              }
            </div>
          </div>
          <div className="betz-card-content">
            <div>
              <span>Weight</span>
              {this.props.units == "metric"
                ? `${parseFloat(betz.weight).toFixed(2) + " kg"}`
                : `${
                    parseInt(betz.weight / 16) +
                    " lbs" +
                    ", " +
                    Math.round(betz.weight % 16) +
                    " oz"
                  }`}
              {this.props.units == "both" && (
                <div className="metric-optional">
                  {"(" +
                    (Math.ceil(betz.weight / 35.274 / 0.05) * 0.05).toFixed(2) +
                    " kg)"}
                </div>
              )}
            </div>
            <div>
              <span>Length</span>
              {this.props.units == "metric"
                ? `${parseFloat(betz.length).toFixed(1) + " cm"}`
                : `${parseFloat(betz.length).toFixed(2) + '"'}`}
              {this.props.units == "both" && (
                <div className="metric-optional">
                  {"(" +
                    ((Math.ceil(betz.length * 2.54) / 0.5) * 0.5).toFixed(1) +
                    " cm)"}
                </div>
              )}
            </div>
            <div>
              <span>Date</span>
              {this.formatDate(betz.date)}
            </div>
            <div>
              <span>Time</span>
              {this.formatTime(betz.time)}
            </div>
          </div>
          {betz.comment && (
            <div className="betz-comment">
              <div className="comment-separator"></div>
              <p>{betz.comment}</p>
            </div>
          )}
          </div>
        </div>
      );
    }, this);
    return (
      <div className="mb-5 mt-3">
        <div className="betz-feed-top medium-12 columns mb-2">
          {/* <a
            href={`/pool/${this.props.pool_id}/calendar-view`}
            className="btn btn-outline-secondary btn-sm calendar-view"
          >
            <FontAwesomeIcon icon={["far", "calendar-alt"]} size="sm" />
          </a> */}
          <h4 className="text-center">All Betz</h4>
          <button className="btn btn-outline-secondary btn-sm px-4 py-1 expand-collapse-all">
            <input type="checkbox" id="arrow" onClick={this.toggleAll} />
            <label htmlFor="arrow"></label>
          </button>
        </div>
        {this.props.numberOfBetz > 5 && sessionStorage.getItem("userData") &&
                JSON.parse(sessionStorage.getItem("userData")).userData
                  .user_id == this.props.pool_owner && JSON.parse(sessionStorage.getItem("userData")).userData
                    .vip !== "1" && 
        <div className='betz-card box-shadow'>
          <div className="boxed">
            <div className="betz-card-header">
              <div className="betz-info">
                <p>You have { this.props.numberOfBetz > 6 ? `${this.props.numberOfBetz - 5} ` : "" }more betz!</p>
                <h6>See Them All</h6>
              </div>
              <span>
                <FontAwesomeIcon
                  icon={faVenusMars}
                  size="lg"
                  className="betz-gender-neutral"
                />
              </span>
            </div>
            <div className="betz-card-content d-block">
              Unlock UNLIMITED betz for only <h4 className="primary-color ml-1 d-inline">$1</h4><br/>with Baby Betz VIP!
            </div>
            <div className="text-center mt-3 mb-1">
              <a href={`/unlock`} className="btn btn-lg btn-primary px-5">Unlock Now <FontAwesomeIcon icon={faCartShopping} size="lg"/></a>
            </div>
          </div>
        </div>}
        <div id="betzCards">
          {betzFeed}
        </div>
        {this.props.numberOfBetz <= 5 && sessionStorage.getItem("userData") &&
                JSON.parse(sessionStorage.getItem("userData")).userData
                  .user_id == this.props.pool_owner && JSON.parse(sessionStorage.getItem("userData")).userData
                  .vip !== "1" && 
        <div className='betz-card box-shadow'>
          <div className="boxed">
            <div className="betz-card-header">
              <div className="betz-info">
                <h6>FREE tier</h6>
                <p>Up to 5 betz</p>
              </div>
              <span>
                <FontAwesomeIcon
                  icon={faVenusMars}
                  size="lg"
                  className="betz-gender-neutral"
                />
              </span>
            </div>
            <div className="betz-card-content d-block">
              Unlock UNLIMITED betz for only <h4 className="primary-color ml-1 d-inline">$1</h4><br/>with Baby Betz VIP!
            </div>
            <div className="text-center mt-3 mb-1">
              <a href={`/unlock`} className="btn btn-lg btn-primary px-5">Unlock Now <FontAwesomeIcon icon={faCartShopping} size="lg"/></a>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default BetzFeed;
