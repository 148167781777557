import React, { Component } from "react";
import Async from "react-async";
import { PostData } from "../../services/postData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faShareAlt,
  faBaby,
  faCogs,
  faChevronRight,
  faCalendarPlus
} from "@fortawesome/free-solid-svg-icons";
import "./poolFeed.scss";

const numberOfBetz = async ({ pool_id: pool_id }) => {
  const res = await fetch(
    "https://app.squareseed.co/api/index.php" + "?tp=" + "numberOfBetz",
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pool_id: pool_id }),
    }
  );
  if (!res.ok) throw new Error(res.statusText);
  return res.json();
};

class PoolFeed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.poolData.length === 0) {
      return (
        <>
        <h6 className="text-center mb-3">Your pools will appear here:</h6>
        <div className="pool_feed-card box-shadow text-center px-2 py-2 mb-5">
          <a href="/add-pool" className="pool_feed-add-first-pool">
            <h2 className="card-text"><FontAwesomeIcon icon={faCalendarPlus}/></h2>
            <div className="">
              Add Your First Pool
            </div>
          </a>
        </div>
        </>
      );
    }
    let poolFeed = this.props.poolData.map(function (poolData, index) {
      // let numberOfBetz = this.getNumberOfBetz(poolData.pool_id);
      return (
        <a href={`/pool/${poolData.pool_id}`} className="pool_feed-card box-shadow" key={index}>
          <div
            className="pool_feed-card-image"
            style={{
              backgroundImage: `url(${
                poolData.pool_img
                  ? poolData.pool_img
                  : "https://www.theodc.net/wp-content/uploads/2018/10/Ultrasound-of-baby-in-mothers-womb..jpg"
              })`,
            }}
          >
          <Async promiseFn={numberOfBetz} pool_id={poolData.pool_id}>
            {({ data, error, isPending }) => {
              if (isPending) return;
              if (error) return `Something went wrong: ${error.message}`;
              if (data)
                return (
                  <div className="pool_feed-card-votes">
                    <h2>{JSON.stringify(data, null, 2)}</h2>
                    <p>{data !== 1 ? "BETS" : "BET"}</p>
                  </div>
                );
              return null;
            }}
          </Async>
          </div>
          <div className="pool_feed-card-body">
            <div className="pool_feed-card-content">
              <h5 className="pool_feed-card-title">{poolData.pool_title}</h5>
              <p className="pool_feed-card-desc">{poolData.pool_desc}</p>
              {/* <div className="card-buttons flex-wrap mr-n1">
                <a
                  href={`/pool/${poolData.pool_id}`}
                  className="btn btn-primary btn-lg mx-1 w-100"
                  id="view-pool-btn"
                >
                  View Pool
                </a>
                <a
                  href={`/pool/${poolData.pool_id}/edit-pool`}
                  className="btn btn-outline-primary btn-sm mx-1 flex-fill"
                >
                  <FontAwesomeIcon icon={faCogs} size="sm" /> Edit
                </a>
                <a
                  href="#"
                  className="btn btn-outline-primary btn-sm mx-1 flex-fill"
                >
                  <FontAwesomeIcon icon={faShareAlt} size="sm" /> Share
                </a>
                { poolData.has_results ? <a
                  href={`/pool/${poolData.pool_id}/edit-results`}
                  className="btn btn-outline-primary btn-sm mx-1 flex-fill"
                >
                  <FontAwesomeIcon icon={faBaby} size="sm" /> Update Results
                </a> : <a
                  href={`/pool/${poolData.pool_id}/add-results`}
                  className="btn btn-outline-primary btn-sm mx-1 flex-fill"
                >
                  <FontAwesomeIcon icon={faBaby} size="sm" /> Baby is Here
                </a> }
              </div> */}
            </div>
          </div>
          <div className="pool_feed-card-arrow">
            <div className="chevron-right"></div>
          </div>
        </a>
      );
    }, this);

    return (
    <>
      <h6 className="text-center mb-3">Your Active Pools:</h6>
      <div className="pool_feed">{poolFeed}</div>
    </>)
  }
}

export default PoolFeed;
