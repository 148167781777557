import React, { Component } from "react";
import { LoadingShape } from 'react-rainbow-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { images } from "../../constants";

class NewUsers extends Component {
    constructor(props) {
        super(props);
    }

    formatLastLogin(timestamp) {
        let lastLogin = new Date(timestamp);
        let now = new Date();
        let timeDiff = (now - lastLogin) / 1000 / 60 - 60;
        if (timeDiff <10) {
            return "Now";
        } else if (timeDiff < 30) {
            return "10min ago";
        } else if (timeDiff < 60) {
            return "30min ago";
        } else if (timeDiff < 120 ) {
            return "1hour ago";
        } else if (timeDiff < 1440) {
            return "2hours ago";
        } else if (timeDiff < 2880) {
            return "yesterday";
        } else if (timeDiff < 4320) {
            return "2days ago";
        } else {
            return "3+ days ago";
        }
    }

    activeDot(timestamp) {
        let lastLogin = new Date(timestamp);
        let now = new Date();
        let timeDiff = (now - lastLogin) / 1000 / 60 - 60;
        return timeDiff < 30 ? <div className="active-dot active-dot-green"/> : <div className="active-dot active-dot-red"/>
    }

    render() {
        if (this.props.newUsers.length === 0) {
            return (
                <>
                <div className="card dashboard-list box-shadow">
                    <h5 className="dashboard-list-title">New Users</h5>
                    <div className="dashboard-list-item">
                        <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                        <div className="dashboard-list-item-content">
                            <div className="dashboard-list-item-info">
                                <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                                <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                            </div>
                            <div className="dashboard-list-item-status">
                                &#x25cf;
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list-separator"></div>
                    <div className="dashboard-list-item">
                        <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                        <div className="dashboard-list-item-content">
                            <div className="dashboard-list-item-info">
                                <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                                <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                            </div>
                            <div className="dashboard-list-item-status">
                                &#x25cf;
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list-separator"></div>
                    <div className="dashboard-list-item">
                        <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                        <div className="dashboard-list-item-content">
                            <div className="dashboard-list-item-info">
                                <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                                <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                            </div>
                            <div className="dashboard-list-item-status">
                                &#x25cf;
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list-separator"></div>
                    <div className="dashboard-list-item">
                        <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                        <div className="dashboard-list-item-content">
                            <div className="dashboard-list-item-info">
                                <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                                <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                            </div>
                            <div className="dashboard-list-item-status">
                                &#x25cf;
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list-separator"></div>
                    <div className="dashboard-list-item">
                        <div style={{width:'50px', height:'50px'}}><LoadingShape variant="avatar" shape="circle" /></div>
                        <div className="dashboard-list-item-content">
                            <div className="dashboard-list-item-info">
                                <div style={{width:'100px', height:'22px'}} className="mb-1 mt-1"><LoadingShape /></div>
                                <div style={{width:'150px', height:'15px'}} className="mb-1"><LoadingShape /></div>
                            </div>
                            <div className="dashboard-list-item-status">
                                &#x25cf;
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-list-separator"></div>
                    <a href="#" className="btn btn-link btn-link-white btn-sm mx-auto mt-2 mb-0">View all users</a>
                </div>
                </>
            );
        }
        let newUsers = this.props.newUsers.map(function (newUser, index) {
            return (
            <React.Fragment key={index}>
            <div className="dashboard-list-item" key={index}>
                {newUser.image ? <img src={newUser.image} alt="user_image"></img> : <div className='dashboard-list-icon'><FontAwesomeIcon icon={faUser} size="lg" /></div>}
                {newUser.vip === "1" && <img src={images.vip_badge} alt="vip_badge" className='vip-user-badge-sm'></img>}
                <div className="dashboard-list-item-content">
                    <div className="dashboard-list-item-info">
                        <h4>{newUser.name}</h4>
                        <p>{newUser.email}</p>
                    </div>
                    <div className="dashboard-list-item-status">
                        {/* &#x25cf; */}
                        {this.activeDot(newUser.last_login)}
                        <p>{this.formatLastLogin(newUser.last_login)}</p>
                    </div>
                </div>
            </div>
            <div className="dashboard-list-separator" key={`separator-${index}`}></div>
            </React.Fragment>
        );
        }, this);

        return (
            <>
            <div className="card dashboard-list box-shadow">
                <h5 className="dashboard-list-title">New Users</h5>
                {newUsers}
                <a href="/dashboard/all-users" className="btn btn-link btn-link-white btn-sm mx-auto mt-2 mb-0">View all users</a>
            </div>
            </>
        )
    }
}

export default NewUsers;