import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Welcome from "././components/welcome/welcome";
import Home from "././components/home/home";
import Login from "././components/login/login";
import Signup from "././components/signup/signup";
import ForgotPassword from "././components/login/forgotPassword";
import Reset from "././components/login/reset";
import Unsubscribe from "././components/login/unsubscribe";
import EditProfile from "././components/home/editProfile";
import EditAvatar from "././components/home/editAvatar";
import AddPool from "././components/addPool/addPool";
import NotFound from "././components/notFound/notFound";
import Faq from "././components/faq/faq";
import Contact from "././components/contact/contact";
import Dashboard from "././components/dashboard/dashboard";
import AllUsers from "././components/dashboard/allUsers";
import AllPools from "././components/dashboard/allPools";
import AllBabies from "././components/dashboard/allBabies";
import Unlock from "././components/unlock/unlock";
import PublicPool from "././components/publicPool/publicPool";
// import CalendarView from "././components/publicPool/calendarView";
import PlaceBetz from "././components/placeBetz/placeBetz";
import AddResults from "././components/addResults/addResults";
import EditResults from "././components/editResults/editResults";
import EditPool from "././components/editPool/editPool";
import EditBetz from "././components/editBetz/editBetz";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Welcome} />
      <Route path="/my-profile" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/reset/:token" component={Reset} />
      <Route path="/unsubscribe/:user_id" component={Unsubscribe} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/edit-profile" component={EditProfile} />
      <Route path="/edit-avatar" component={EditAvatar} />
      <Route path="/add-pool" component={AddPool} />
      <Route path="/faq" component={Faq} />
      <Route path="/contact" component={Contact} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/dashboard/all-users" component={AllUsers} />
      <Route path="/dashboard/all-pools" component={AllPools} />
      <Route path="/dashboard/all-babies" component={AllBabies} />
      <Route path="/unlock" component={Unlock} />
      <Route exact path="/pool/:pool_id" component={PublicPool} />
      {/* <Route exact path="/pool/:pool_id/calendar-view" component={CalendarView} /> */}
      <Route path="/pool/:pool_id/place-betz" component={PlaceBetz} />
      <Route path="/pool/:pool_id/add-results" component={AddResults} />
      <Route path="/pool/:pool_id/edit-results" component={EditResults} />
      <Route path="/pool/:pool_id/edit-pool" component={EditPool} />
      <Route path="/pool/:pool_id/betz/:bet_id/edit-betz" component={EditBetz}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
