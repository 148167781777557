import React, { Component } from "react";
import "./header.scss";
import { images } from "../../constants";
class Header extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      redirectToReferrer: false,
    };

    this.logout = this.logout.bind(this);
  }

  logout() {
    sessionStorage.setItem("userData", "");
    sessionStorage.setItem("poolData", "");
    sessionStorage.clear();
    localStorage.clear();
    this.setState({ redirectToReferrer: true });
  }

  render() {
    return (
      <div className={`app__header ${
        this.props.theme === "color" ? "navbar-color" : this.props.theme === "white" ? "navbar-white" : ""
      }`} id="Header">
        <div className="app__header-item">
          <a href="/faq">About</a>
        </div>
        <div className="app__header-item">
          <a href="/contact">Contact</a>
        </div>
        <div className="app__header-logo">
          <a href="../" target="_self"><img src={images.logo_white} alt="logo"/></a>
        </div>
        {sessionStorage.getItem("userData") ? <>
          <div className="app__header-item">
          <a href="/my-profile">My Profile</a>
          </div>
          <div className="app__header-item">
          <a href="#" onClick={this.logout}>Logout</a>
          </div>
        </> : <>
        <div className="app__header-item">
        <a href="/signup">Sign up</a>
        </div>
        <div className="app__header-item">
          <a href="/login">Log in</a>
            </div>
            </>
        }
        

        {/* <MobileHeader
          name={this.state.navbar.name}
          back={this.state.navbar.back}
          backLink={this.state.navbar.backLink}
          theme="color"
          menu={this.state.navbar.menu}
          menuLink={this.state.navbar.menuLink}
        /> */}
      </div>
    );
  }
}
export default Header;
