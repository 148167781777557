import hero_pregnant_belly from "../assets/hero_pregnant_belly.jpg";
import image_pregnant_belly from "../assets/image_pregnant_belly.jpg";
import logo_white from "../assets/logo_white.png";
import bottom_white_wave_01 from "../assets/bottom_white_wave_01.png";
import top_white_wave_01 from "../assets/top_white_wave_01.png";
import smartphone_mockup_01 from "../assets/smartphone_mockup_01.png";
import smartphone_mockup_02 from "../assets/smartphone_mockup_02.png";
import smartphone_mockup_03 from "../assets/smartphone_mockup_03.png";
import smartphone_mockup_04 from "../assets/smartphone_mockup_04.png";
import square_seed_white from "../assets/square_seed_white.png";
import not_found_moon from "../assets/not_found_moon.png";
import vip_badge from "../assets/vip_badge.png";

export default {
  hero_pregnant_belly,
  image_pregnant_belly,
  logo_white,
  bottom_white_wave_01,
  top_white_wave_01,
  smartphone_mockup_01,
  smartphone_mockup_02,
  smartphone_mockup_03,
  smartphone_mockup_04,
  square_seed_white,
  not_found_moon,
  vip_badge,
};
