import React, { Component } from "react";
import { PostData } from "../../services/postData";
import { Redirect } from "react-router-dom";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward } from "@fortawesome/free-solid-svg-icons";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./unlock.scss";

class Unlock extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Get VIP",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      loading: "",
      errors: {},
      success: "",
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    // console.log(process.env.REACT_APP_PAYPAL_CLIENT_ID);
    if (localStorage.getItem("userData")) {
      sessionStorage.setItem("userData", localStorage.getItem("userData"))
    }
  }

  logOrder(order) {
    let errors = {};
    let postData ={
      order_id: order.id,
      update_time: order.update_time,
      status: order.status,
      price: order.purchase_units[0].amount.value,
      payer_email: order.payer.email_address,
      given_name: order.payer.name.given_name,
      surname: order.payer.name.surname,
      email: JSON.parse(sessionStorage.getItem("userData")).userData.email,
      user_id: JSON.parse(sessionStorage.getItem("userData")).userData.user_id
    };
    this.setState({ loading: true });
    PostData("logOrder", postData).then((result) => {
      let responseJson = result;
      if (responseJson.success) {
        this.setState({
          loading: false,
          success: result.success
        });
        this.setState({ redirectToReferrer: true });
      } else {
        //alert(result.error);
        this.setState({ loading: false });
        this.setState({
          errors: errors,
        });
        console.log(this.state.errors);
      }
    });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect
        to={{
          pathname: "/my-profile",
          state: { vipAlert: "Welcome to Baby Betz VIP!", vip: "1"}
        }}
      />;
    };
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        />
      <div className="faq-top my_pools-circle-lip my_pools-circle-lip-shorter box-shadow mb-4 px-4">
        <div className="large-only boxed text-center d-none d-lg-block">
          <h1>{this.state.navbar.name}</h1>
        </div>
        <div className="text-center boxed">
          <p>Unlock UNLIMITED betz<br/>for all your pools.</p>
        </div>
        <FontAwesomeIcon icon={faAward} size="5x"/>
      </div>
      {/* <h3 className="large-only text-center d-none d-lg-block my-4">Get in touch with us.</h3> */}
      <div className="baby-betz-pro boxed mb-4">
        <div className="backdrop box-shadow text-center px-5 mb-4">
          <h4 className="mb-1">Get Baby Betz VIP</h4>
          <h3 className="primary-color mb-3">$1.00</h3>
        {/* <PayPalScriptProvider options={{ clientId: "AVVx_sxFZcxV97ofsCCQ3xzyjbZShcvrI6i2mTPgwAtH764Yh9YmEDSrQibvfNJBEr0qJ9zqtJ3M2hY5", enableFunding:"venmo"}}> */}
        <PayPalScriptProvider options={{ clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID, enableFunding:"venmo" }}>
            <PayPalButtons
                style={{
                  color: "gold",
                  layout: "vertical",
                  tagline: false,
                  shape: "pill"
                }}
                onClick={(data, actions) => {
                  return actions.resolve()
                }}
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        description: "Baby Betz VIP",
                        amount: { value: 1.00 }
                      }
                    ]
                  })
                }}
                onApprove={async(data, actions) => {
                  const order = await actions.order.capture()
                  console.log("order", order);
                  if (order.status === "COMPLETED") {
                    this.logOrder(order)
                  }

                  // handleApprove(data.orderID);
                  }
                }
                onCancel={() => {
                  console.log("PayPal order canceled");
                }}
                onError={(err) => {
                  this.setState({error: err})
                  console.log("PayPal Order Error ",err);
                }}
            />
        </PayPalScriptProvider>
        </div>
      </div>
      </>
    );
  }
}
export default Unlock;
