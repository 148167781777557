import React, { Component, useState } from "react";
import { PostData } from "../../services/postData";
import { Redirect } from "react-router-dom";
import { Header } from "../../components";
import MobileHeader from "../mobileHeader/mobileHeader";
import { CheckboxGroup } from "react-rainbow-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "../../styles/floating-labels.css";
import "../../styles/custom.css";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: {
        name: "Sign Up",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      type1: "password",
      icon1: faEyeSlash,
      type2: "password",
      icon2: faEyeSlash,
      activate: false,
      redirectToReferrer: false,
      message: "",
      subscribe: ['email'],
      loading: "",
      errors: {},
    };
    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.handleToggle1 = this.handleToggle1.bind(this);
    this.handleToggle2 = this.handleToggle2.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  signup = (event) => {
    event.preventDefault();
    document.getElementById("alert").classList.remove("alert");
    if (this.validate()) {
      console.log(this.state);
      if (
        this.state.name &&
        this.state.email &&
        this.state.password &&
        this.state.passwordConfirm
      ) {
        if (this.state.password === this.state.passwordConfirm) {
          let errors = {};
          this.setState({ loading: true });
          PostData("signup", this.state).then((result) => {
            let responseJson = result;
            console.log("responseJson: " + JSON.stringify(responseJson));
            this.setState({ message: "" });
            if (responseJson.userData) {
              // sessionStorage.setItem("userData", JSON.stringify(responseJson));
              this.setState({ loading: false });
              this.setState({ activate: true });
              // this.setState({ redirectToReferrer: true });
            } else {
              //alert(result.error);
              errors["signup"] = result.error;
              document.getElementById("alert").classList.add("alert");
              this.setState({ loading: false });
              this.setState({
                errors: errors,
              });
            }
          });
        } else {
          this.setState({ message: "The passwords don't match." });
        }
      } else {
        this.setState({ message: "Please fill out all fields." });
      }
    }
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.name === "") {
      isValid = false;
      errors["name"] = "All fields are required.";
      document.getElementById("inputName").classList.add("is-invalid");
    } else {
      document.getElementById("inputName").classList.remove("is-invalid");
    }

    if (this.state.email === "") {
      isValid = false;
      errors["email"] = "Please enter your email address.";
      document.getElementById("inputEmail").classList.add("is-invalid");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        document.getElementById("inputEmail").classList.remove("is-invalid");
      }
    }

    if (this.state.password === "") {
      isValid = false;
      errors["password"] = "Please enter a password.";
      document.getElementById("inputPassword").classList.add("is-invalid");
    } else if (this.state.password.length < 8) {
      isValid = false;
      errors["password"] = "The password has to be minimum 8 characters.";
      document.getElementById("inputPassword").classList.add("is-invalid");
    } else {
      document.getElementById("inputPassword").classList.remove("is-invalid");
    }

    if (this.state.passwordConfirm === "") {
      isValid = false;
      errors["passwordConfirm"] = "Please enter a password.";
      document
        .getElementById("inputPasswordConfirm")
        .classList.add("is-invalid");
    } else if (this.state.password !== this.state.passwordConfirm) {
      isValid = false;
      errors["passwordConfirm"] = "The passwords don't match.";
      document
        .getElementById("inputPasswordConfirm")
        .classList.add("is-invalid");
    } else {
      document
        .getElementById("inputPasswordConfirm")
        .classList.remove("is-invalid");
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  onCheckboxChange(value) {
    this.setState({subscribe: value})
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeName(e) {
    this.setState({ name: e.target.value }, () => {
      let error = "";

      if (this.state.name === "") {
        error = "Please enter your name.";
        document.getElementById("inputName").classList.add("is-invalid");
      } else {
        document.getElementById("inputName").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.name = error;
        return { errors };
      });
    });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value }, () => {
      let error = "";

      if (this.state.email === "") {
        error = "Please enter your email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(this.state.email)) {
          error = "Please enter a valid email address.";
          document.getElementById("inputEmail").classList.add("is-invalid");
        } else {
          document.getElementById("inputEmail").classList.remove("is-invalid");
        }
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.email = error;
        return { errors };
      });
    });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value }, () => {
      let error = "";

      if (this.state.password === "") {
        error = "Please enter a password.";
        document.getElementById("inputPassword").classList.add("is-invalid");
      } else if (this.state.password.length < 8) {
        error = "The password has to be minimum 8 characters.";
        document.getElementById("inputPassword").classList.add("is-invalid");
      } else {
        document.getElementById("inputPassword").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.password = error;
        return { errors };
      });
    });
  }

  onChangePasswordConfirm(e) {
    this.setState({ passwordConfirm: e.target.value }, () => {
      let error = "";

      if (this.state.passwordConfirm === "") {
        error = "Please enter a password.";
        document
          .getElementById("inputPasswordConfirm")
          .classList.add("is-invalid");
      } else if (this.state.password !== this.state.passwordConfirm) {
        error = "The passwords don't match.";
        document
          .getElementById("inputPasswordConfirm")
          .classList.add("is-invalid");
      } else {
        document
          .getElementById("inputPasswordConfirm")
          .classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.passwordConfirm = error;
        return { errors };
      });
    });
  }

  handleToggle1() {
    if (this.state.type1 === 'password') {
       this.setState({ icon1: faEye});
       this.setState({ type1: "text"});
    } else {
      this.setState({ icon1: faEyeSlash});
       this.setState({ type1: "password"});
    }
  }

  handleToggle2() {
    if (this.state.type2 === 'password') {
      this.setState({ icon2: faEye});
      this.setState({ type2: "text"});
    } else {
      this.setState({ icon2: faEyeSlash});
      this.setState({ type2: "password"});
    }
  }

  hiddenEmail() {
    let hidden = this.state.email.replace(/^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
    return hidden;
  }

  render() {
    if (this.state.redirectToReferrer || sessionStorage.getItem("userData")) {
      return <Redirect to={"/my-profile"} />;
    }
    if (this.state.activate) {
      return (
        <>
        <Header theme="color"/>
        <MobileHeader
          name={this.state.navbar.name}
          back={this.state.navbar.back}
          backLink={this.state.navbar.backLink}
          menu={this.state.navbar.menu}
          menuLink={this.state.navbar.menuLink}
          theme="white"
          redirectToReferrer={!this.state.redirectToReferrer}
        />
        <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
        <div className="boxed mb-5" id="sBody">
        <div className="backdrop box-shadow mb-4">
          <div className="medium-12 columns left">
            <h4 className="center mb-4">Signup successful!</h4>
            <h6 className="text-center mb-4">
              Please check your email <strong>{this.hiddenEmail()}</strong> to activate your account.
            </h6>
            <div className="alert alert-info mb-1" role="alert" id="alert">
              Make sure to check your spam or junk folder if you don't see the email in your inbox.
            </div>
          </div>
        </div>
      </div>
        </>
      );
    }
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="white"
        redirectToReferrer={!this.state.redirectToReferrer}
      />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed mb-4" id="sBody">
        <div className="backdrop box-shadow mb-4">
          <div className="medium-12 columns left">
            {/* <button
              type="button"
              className="btn btn-lg btn-block btn-outline-primary btn-fb"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#4267b2"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
              Continue with Facebook
            </button> */}
            <h6 className="center mb-4">Sign up with your email</h6>
            <form onSubmit={this.login}>
            <div className="form-label-group signup-label">
              <input
                type="text"
                name="name"
                id="inputName"
                className="form-control"
                placeholder="Name"
                required=""
                autoFocus=""
                onChange={this.onChangeName}
              />
              <label htmlFor="inputName">Name</label>
              <div className="invalid-feedback">{this.state.errors.name}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control"
                placeholder="Email"
                required=""
                autoFocus=""
                onChange={this.onChangeEmail}
              />
              <label htmlFor="inputEmail">Email</label>
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type={this.state.type1}
                name="password"
                id="inputPassword"
                className="form-control"
                placeholder="Password"
                required=""
                autoFocus=""
                onChange={this.onChangePassword}
              />
              <label htmlFor="inputPassword">Password</label>
              <span className="form-password-show-hide" onClick={this.handleToggle1}>
                <FontAwesomeIcon icon={this.state.icon1} />
              </span>
              <div className="invalid-feedback">
                {this.state.errors.password}
              </div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type={this.state.type2}
                name="passwordConfirm"
                id="inputPasswordConfirm"
                className="form-control"
                placeholder="Confirm Password"
                required=""
                autoFocus=""
                onChange={this.onChangePasswordConfirm}
              />
              <label htmlFor="inputPasswordConfirm">Confirm Password</label>
              <span className="form-password-show-hide" onClick={this.handleToggle2}>
                <FontAwesomeIcon icon={this.state.icon2} />
              </span>
              <div className="invalid-feedback">
                {this.state.errors.passwordConfirm}
              </div>
            </div>
            <div className="rainbow-p-vertical_large rainbow-p-left_xx-large">
              <CheckboxGroup
                  id="subscribeCheckbox"
                  options={[{ value: 'email', label: 'I would like to receive email updates about my pools.', disabled: false }]}
                  value={this.state.subscribe}
                  onChange={this.onCheckboxChange}
              />
            </div>
            <div className="alert-warning" role="alert" id="alert">
              {this.state.errors.signup}
              {this.state.errors.signup === "This email already exists in our system. " && <a href="/login" onClick={this.resend} className="alert-link">Log in</a>}
              {this.state.errors.signup === "This email already exists in our system. " && " instead?"}
            </div>
            <button
              className="btn btn-lg btn-primary btn-block my-4"
              type="submit"
              onClick={this.signup}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Create Free Account
            </button>
            <input type="submit" hidden />
            </form>
            <h6 className="text-center my-2">
              Already have an account? <a href="/login">Log in</a>
            </h6>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default Signup;
