import React, { Component } from "react";
import { PostData } from "../../services/postData";
import { Redirect } from "react-router-dom";
import { Header } from "..";
import MobileHeader from "../mobileHeader/mobileHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/floating-labels.css";
import "../../styles/custom.css";

class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbar: {
        name: "Unsubscribe",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      user_id: "",
      name: "",
      email: "",
      redirectToReferrer: false,
      message: "",
      loading: "",
      unsubscribed: "",
      errors: {},
    };
    this.getUser = this.getUser.bind(this);
    this.unsubscribeEmail = this.unsubscribeEmail.bind(this);
  }

  componentDidMount() {
    console.log(this.props.match.params.user_id);
    this.setState({ user_id: this.props.match.params.user_id });
    this.getUser(this.props.match.params.user_id);
  }
  getUser(user_id) {
    PostData("getUser", {user_id: user_id}).then((result) => {
      let responseJson = result;
      console.log("responseJson: " + JSON.stringify(responseJson));
      if (responseJson.userData) {
        this.setState({
          name: responseJson.userData.name,
          email: responseJson.userData.email,
        });
      } else { // no user data
        // this.setState({ redirectToReferrer: true });
      }
    });
  }

  hiddenEmail() {
    let hidden = this.state.email.replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c
    );
    return hidden;
  }

  unsubscribeEmail() {
    document.getElementById("alert").classList.remove("alert");
    let errors = {};
    this.setState({ loading: true });
    console.log({ email: this.state.email });
    PostData("unsubscribeEmail", { email: this.state.email }).then((result) => {
      let responseJson = result;
      console.log("responseJson: " + JSON.stringify(responseJson));
      this.setState({ message: "" });
      if (responseJson.success) {
        this.setState({
          loading: false,
          unsubscribed: responseJson.success
        });
        document.getElementById("alert").classList.add("alert");
        // this.setState({ redirectToReferrer: true });
      } else {
        //alert(result.error);
        console.log(JSON.stringify(responseJson));
        errors["unsubscribe"] = result.error;
        this.setState({ loading: false });
        this.setState({
          errors: errors,
        });
      }
    });
  }

  render() {
    if (sessionStorage.getItem("userData")) {
      return <Redirect to={"/edit-profile"} />;
    };
    if (this.state.redirectToReferrer) {
      return <Redirect
        to={"/"} />;
    };
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="white"
        redirectToReferrer={!this.state.redirectToReferrer}
      />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed mb-4" id="sBody">
        <div className="backdrop box-shadow mb-4">
          <div className="medium-12 columns left px-4">
            <h6 className="center mb-4">Hi {this.state.name.replace(/ .*/,'')},</h6>
            <p>Click the "Unsubscribe" button to stop receiving e-mail notifications from Baby Betz.</p><p>If you unsubscribe, you will stop receiving messages to <strong>{this.hiddenEmail()}</strong></p>
            <button
              className="btn btn-lg btn-primary btn-block my-4"
              type="submit"
              onClick={this.unsubscribeEmail}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Unsubscribe
            </button>
            <div className="alert-success " role="alert" id="alert">
              {this.state.unsubscribed}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default Unsubscribe;
