import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import { Header } from "../../components";
import MobileHeader from "../mobileHeader/mobileHeader";
import { CheckboxGroup } from "react-rainbow-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "../../styles/floating-labels.css";
import "./login.scss";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Login",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      email: "",
      password: "",
      type: "password",
      icon: faEyeSlash,
      remember: ['remember'],
      activated: false,
      resent: "",
      redirectToReferrer: false,
      loading: "",
      errors: {},
    };

    this.login = this.login.bind(this);
    this.validate = this.validate.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.resend = this.resend.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  componentDidMount() {
    new URLSearchParams(this.props.location.search).get("activated") && this.setState({ activated: true });
    console.log(this.state.remember.includes('remember'));
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.email === "") {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
      document.getElementById("inputEmail").classList.add("is-invalid");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        document.getElementById("inputEmail").classList.remove("is-invalid");
      }
    }

    if (this.state.password === "") {
      isValid = false;
      errors["password"] = "Please enter a password.";
      document.getElementById("inputPassword").classList.add("is-invalid");
    } else {
      document.getElementById("inputPassword").classList.remove("is-invalid");
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  login = (event) => {
    event.preventDefault();
    document.getElementById("alert").classList.remove("alert");
    document.getElementById("alert-activate").classList.remove("alert");
    document.getElementById("alert-resent").classList.remove("alert");
    this.setState({ activation: "", resent: "" })
    if (this.validate()) {
      let errors = {};
      this.setState({ loading: true });
      PostData("login", this.state).then((result) => {
        let responseJson = result;
        if (responseJson.userData) {
          this.state.remember.includes('remember') ? localStorage.setItem("userData", JSON.stringify(responseJson)) : sessionStorage.setItem("userData", JSON.stringify(responseJson));
          this.setState({ loading: false });
          // this.setState({ redirectToReferrer: true });
        } else if (responseJson.error) {
          //alert(result.error);
          console.log(responseJson.error);
          errors["login"] = responseJson.error;
          document.getElementById("alert").classList.add("alert");
        } else if (responseJson.activation) {
          console.log(responseJson.activation);
          console.log(this.state.email);
          this.setState({ activation: responseJson.activation })
          document.getElementById("alert-activate").classList.add("alert");
          let resend = document.createElement('button');
          resend.innerHTML = "resend confirmation email";
          resend.setAttribute("onclick", this.resend);
          resend.classList.add("alert-link");
          // document.getElementById("alert-activate").appendChild(resend);
        };
        this.setState({ loading: false });
        this.setState({
          errors: errors,
        });
      });
    }
  }

  resend() {
    document.getElementById("alert").classList.remove("alert");
    document.getElementById("alert-activate").classList.remove("alert");
    document.getElementById("alert-resent").classList.remove("alert");
    console.log("resending activation email");
    this.setState({ activation: "", resent: "" })
    PostData("resendActivation", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.resent) {
        this.setState({ loading: false });
        this.setState({ resent: responseJson.resent })
        document.getElementById("alert-resent").classList.add("alert");
      } else {
        console.log(JSON.stringify(responseJson));
      }
    });
  }

  onCheckboxChange(value) {
    this.setState({remember: value})
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  }

  handleToggle() {
    if (this.state.type === 'password') {
       this.setState({ icon: faEye});
       this.setState({ type: "text"});
    } else {
      this.setState({ icon: faEyeSlash});
       this.setState({ type: "password"});
    }
 }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/my-profile"} />;
    }
    if (sessionStorage.getItem("userData") || localStorage.getItem("userData")) {
      return <Redirect to={"/my-profile"} />;
    }
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="white"
        redirectToReferrer={!this.state.redirectToReferrer}
      />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed mb-4" id="Body">
        <div className="backdrop box-shadow">
          <div className="medium-12 columns left">
            {this.state.activated &&
            <div className="alert alert-info" role="alert" id="activated-info">
              Your account has been successfully activated! You can now log in!
            </div>}
            {/* <button
              type="button"
              className="btn btn-lg btn-block btn-outline-primary btn-fb"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#4267b2"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
              Login with Facebook
            </button> */}
            <h6 className="center mb-4">Please enter your account information below.</h6>
            <form onSubmit={this.login}>
            <div className="form-label-group signup-label">
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control"
                placeholder="email"
                required=""
                autoFocus=""
                onChange={this.onChange}
              />
              <label htmlFor="inputEmail">Email</label>
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type={this.state.type}
                id="inputPassword"
                name="password"
                className="form-control"
                placeholder="Password"
                required={true}
                onChange={this.onChange}
              />
              <div className="invalid-feedback">
                {this.state.errors.password}
              </div>
              <label htmlFor="inputPassword">Password</label>
              <span className="form-password-show-hide" onClick={this.handleToggle}>
                <FontAwesomeIcon icon={this.state.icon} />
              </span>
            </div>
            <div className="rainbow-p-vertical_large rainbow-p-left_xx-large rainbow-p-half">
              <CheckboxGroup
                  id="rememberCheckbox"
                  options={[{ value: 'remember', label: 'Remember me', disabled: false }]}
                  value={this.state.remember}
                  onChange={this.onCheckboxChange}
              />
            </div>
            <div className="text-right forgot-pass mb-3">
              <a href="/forgot-password" className="text-right">
                Forgot Password?
              </a>
            </div>
            <div className="alert-info" role="alert" id="alert-resent">
                {this.state.resent} 
            </div>
            <div className="alert-danger" role="alert" id="alert-activate">
              {this.state.activation}
              {this.state.activation && <a href="#" onClick={this.resend} className="alert-link">resend confirmation email</a>}
            </div>
            <div className="alert-danger" role="alert" id="alert">
                {this.state.errors.login}
            </div>
            <button
              className="btn btn-lg btn-primary btn-block"
              type="submit"
              onClick={this.login}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Login
            </button>
            <input type="submit" hidden />
            </form>
            <p className="text-center my-3">&mdash; OR &mdash;</p>
            <a
              className="btn btn-lg btn-outline-primary btn-block"
              href="/signup"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default Login;
