import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "../../components";
import {Helmet} from "react-helmet";
import CountUp from "react-countup";
import BetzFeed from "../betzFeed/betzFeed";
import PoolInfo from "../poolInfo/poolInfo";
import Results from "../results/results";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faBalanceScaleRight,
  faArrowUpWideShort,
  faVenusMars,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/custom.css";
import "./publicPool.scss";

class PublicPool extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      pool_id: "",
      pool_title: "",
      pool_desc: "",
      due_date: "",
      limit_date: "",
      date_format: "mm-dd-yyyy",
      time_format: "12h",
      units: "imperial",
      theme: "light",
      color: "",
      numberOfBetz: "",
      has_results: null,
      allBetz: [],
      pool_data: {
        gender: {
          M: 0,
          F: 0,
        },
        weight: 0,
        length: 0,
      },
      showGoTop: "goTopHidden",
      redirectToReferrer: false,
    };

    this.getPool = this.getPool.bind(this);
    this.getBetz = this.getBetz.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.scrollUp);
    if (localStorage.getItem("userData")) {
      sessionStorage.setItem("userData", localStorage.getItem("userData"))
    }
    sessionStorage.removeItem("newPoolData");
    if (sessionStorage.getItem("userData")) {
      this.setPoolId();
    } else {
      // this.setState({ redirectToReferrer: true });
      this.setPoolId();
    }
  }

  setPoolId() {
    this.setState({ pool_id: this.props.match.params.pool_id }, () => {
      this.getPool();
    });
  }

  getPool() {
    PostData("pool", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.poolData) {
        // sessionStorage.setItem("poolData", JSON.stringify(responseJson));
        // this.setState({ redirectToReferrer: true });

        this.setState({
          pool_owner: responseJson.poolData.user_id,
          pool_title: responseJson.poolData.pool_title,
          pool_desc: responseJson.poolData.pool_desc,
          pool_img: responseJson.poolData.pool_img,
          due_date: responseJson.poolData.due_date,
          date_format: responseJson.poolData.date_format,
          time_format: responseJson.poolData.time_format,
          units: responseJson.poolData.units,
          limit_date: responseJson.poolData.limit_date,
          theme: responseJson.poolData.theme,
          color: responseJson.poolData.color,
          has_results: responseJson.poolData.has_results,
        });
        this.getNumberOfBetz();
        this.getBetz();
      } else
        this.setState({ redirectToReferrer: true });
        // alert(result.error);
    });
  }

  getNumberOfBetz() {
    if (this.state.pool_id) {
      PostData("numberOfBetz", { pool_id: this.state.pool_id }).then((result) => {
        if (result) {
          this.setState({ numberOfBetz: result });
        } else {
          console.log("couldn't fetch number of bets")
        }
      });
    } else {
      console.log("no pool id found")
    }
  }

  getBetz() {
    if (this.state.pool_id) {
      PostData("getBetz", { pool_id: this.state.pool_id }).then((result) => {
        let responseJson = result;
        if (responseJson.allBetz) {
          this.setState({ allBetz: responseJson.allBetz }, () =>
            this.getAverage()
          );
        }
      });
    }
  }

  getAverage() {
    let betz = this.state.allBetz,
      M = 0,
      F = 0,
      weight = 0,
      length = 0;
    for (let i = 0; i < betz.length; i++) {
      if (betz[i]["gender"] === "M") {
        M++;
      } else if (betz[i]["gender"] === "F") {
        F++;
      }
      weight += parseFloat(betz[i]["weight"]);
      length += parseFloat(betz[i]["length"]);
    }
    weight /= betz.length;
    length /= betz.length;
    this.setState((prevState) => ({
      pool_data: {
        ...prevState.pool_data,
        gender: {
          ...prevState.pool_data.gender,
          M: M,
          F: F,
        },
        weight: weight,
        length: length,
      },
    }));
  }

  scrollUp = () => {
    const position = window.scrollY;
    this.setState({ position });

    if (this.state.position > 700) {
      this.setState({showGoTop: "goTop"});
    } else if (this.state.position < 400) {
      this.setState({showGoTop: "goTopHidden"});
    }
  };
  
  handleScrollUp = () => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth" });
  };

  render() {
    let boy_width =
      (this.state.pool_data.gender.M /
        (this.state.pool_data.gender.M + this.state.pool_data.gender.F)) *
      100;
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/404"} />;
    }

    // if (this.state.redirectToReferrer) {
    //   return <Redirect to={"/login"} />;
    // }

    // // if (this.state.redirectToReferrer) {
    // //   return <Redirect to={"/home"} />;
    // // }
    // // if (sessionStorage.getItem("userData")) {
    // //   return <Redirect to={"/home"} />;
    // // }

    // let poolFeed = this.props.poolData.map(function (poolData, index) {
    //   return (
    //     <div className="row" id="Body">
    //       <h4>Pool {pool_id} will come here...</h4>
    //       <div className="medium-5 columns left">
    //         <b>{poolData.pool_title}</b>
    //         <p>{poolData.pool_desc}</p>
    //       </div>
    //     </div>
    //   );
    // }, this);

    //return <div>{poolFeed}</div>;
    return (
      <>
      <Header theme={this.state.has_results ? "color" : "white"}/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={"/my-profile"}
        // theme={"color"}
        theme={this.state.has_results ? "color" : "white"}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{ `Baby Betz - ${this.state.pool_title}` }</title>
        <meta name="title" key="title" content={this.state.pool_title} />
        <meta name="description" key="description" content={this.state.pool_desc} />
        <meta property="og:image" key="og:image" content={ this.state.pool_img ? this.state.pool_img : "https://app.squareseed.co/assets/images/og-image.jpg" } />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta property="og:title" key="og:title" content={this.state.pool_title} />
        <meta property="og:description" key="og:description" content={this.state.pool_desc} />
      </Helmet> */}
      <div className={this.state.showGoTop} onClick={this.handleScrollUp}>
        <button className="goTop btn-primary">
          <FontAwesomeIcon icon={faChevronUp} size="lg" className="goTop-text"/>
        </button>
      </div>
      {/* Results or Pool Info */}
      {this.state.has_results ? <Results
          pool_id={this.state.pool_id}
          units={this.state.units}
          date_format={this.state.date_format}
          time_format={this.state.time_format}
          pool_owner={this.state.pool_owner}
      /> : <PoolInfo
          pool_id={this.state.pool_id}
          pool_title={this.state.pool_title}
          pool_desc={this.state.pool_desc}
          pool_img={this.state.pool_img}
          date_format={this.state.date_format}
          due_date={this.state.due_date}
          limit_date={this.state.limit_date}
          numberOfBetz={this.state.numberOfBetz}
          pool_owner={this.state.pool_owner}
      /> }
      <div className="boxed" id="Body">
        <div className="medium-12 columns">
          <div
            className="card text-center box-shadow"
            style={{ overflow: "hidden", zIndex: "1" }}
          >
            <div className="card-body px-4 pb-4 pt-3">
              <FontAwesomeIcon
                icon={faVenusMars}
                size="4x"
                className="icon-huge icon-small-top-center"
              />
              <h6 className="card-title">Sex</h6>
              <div className="gender-container">
                <div
                  className="gender-pill gender-boy"
                  style={{ width: `${boy_width}%` }}
                >
                  <FontAwesomeIcon icon={faMars} />
                  {this.state.pool_data.gender.M}
                </div>
                <div className="gender-pill gender-girl">
                  {this.state.pool_data.gender.F}
                  <FontAwesomeIcon icon={faVenus} />
                </div>
              </div>
            </div>
          </div>
          <div className="card-half-container">
            <div className="card card-half text-center box-shadow">
              <div className="card-body px-3 py-3">
                <FontAwesomeIcon
                  icon={faBalanceScaleRight}
                  size="lg"
                  className="icon-small icon-small-top-right"
                />
                <div className="card-half-title">
                  <p>AVERAGE</p>
                  <h6>Weight</h6>
                </div>
                <h4>
                  {this.state.units === "metric" ? (
                    <CountUp
                      start={0}
                      end={this.state.pool_data.weight}
                      duration={2}
                      separator="x"
                      decimals={2}
                      decimal=","
                      suffix=" kg"
                      key="kg"
                    ></CountUp>
                  ) : (
                    [
                      <CountUp
                        start={0}
                        end={parseInt(this.state.pool_data.weight / 16)}
                        duration={1}
                        separator="x"
                        decimals={0}
                        decimal="."
                        suffix=" lbs, "
                        key="lbs"
                      ></CountUp>,
                      <CountUp
                        start={0}
                        end={Math.round(this.state.pool_data.weight % 16)}
                        duration={2}
                        separator="x"
                        decimals={0}
                        decimal="."
                        suffix=" oz"
                        key="oz"
                      ></CountUp>,
                    ]
                  )}
                  {this.state.units === "both" && (
                    <div className="metric-optional">
                      (
                      {
                        <CountUp
                          start={0}
                          end={
                            Math.ceil(
                              this.state.pool_data.weight / 35.274 / 0.05
                            ) * 0.05
                          }
                          duration={2}
                          separator=" "
                          decimals={2}
                          decimal=","
                          suffix=" kg"
                        ></CountUp>
                      }
                      )
                    </div>
                  )}
                </h4>
                <div className="gender-container"></div>
              </div>
            </div>
            <div className="card card-half text-center box-shadow">
              <div className="card-body px-3 py-3">
                <FontAwesomeIcon
                  icon={faArrowUpWideShort}
                  size="lg"
                  flip="horizontal"
                  className="icon-small icon-small-top-right"
                />
                <div className="card-half-title">
                  <p>AVERAGE</p>
                  <h6>Length</h6>
                </div>
                <h4>
                  {this.state.units === "metric" ? (
                    <CountUp
                      start={0}
                      end={this.state.pool_data.length}
                      duration={2}
                      separator=" "
                      decimals={1}
                      decimal=","
                      suffix=" cm"
                    ></CountUp>
                  ) : (
                    <CountUp
                      start={0}
                      end={this.state.pool_data.length}
                      duration={2}
                      separator=" "
                      decimals={2}
                      decimal="."
                      suffix='"'
                    ></CountUp>
                  )}
                  {this.state.units === "both" && (
                    <div className="metric-optional">
                      (
                      {
                        <CountUp
                          start={0}
                          end={
                            (Math.ceil(this.state.pool_data.length * 2.54) /
                              0.5) *
                            0.5
                          }
                          duration={2}
                          separator=" "
                          decimals={1}
                          decimal="."
                          suffix=" cm"
                        ></CountUp>
                      }
                      )
                    </div>
                  )}
                  </h4>
                <div className="gender-container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BetzFeed
        numberOfBetz={this.state.numberOfBetz}  
        allBetz={this.state.allBetz}
        units={this.state.units}
        date_format={this.state.date_format}
        time_format={this.state.time_format}
        pool_owner={this.state.pool_owner}
        pool_id={this.state.pool_id}
      />
      </>
    );
  }
}
export default PublicPool;
