import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import { Header } from "../../components";
import MobileHeader from "../mobileHeader/mobileHeader";
import { CheckboxGroup } from "react-rainbow-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import "../../styles/react-confirm-alert.css";

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbar: {
        name: "Edit Profile",
        back: true,
        backLink: "/my-profile",
        menu: false,
        menuLink: "",
      },
      userName: "",
      userId: "",
      oldEmail: "",
      email: "",
      image: "",
      subscribe: [],
      loading: false,
      errors: {},
      redirectToReferrer: false,
    };

    this.getProfileDetails = this.getProfileDetails.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData")) {
      this.getProfileDetails();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }

  getProfileDetails() {
    let data = JSON.parse(sessionStorage.getItem("userData"));
    let userName = data.userData.name;
    let userId = data.userData.user_id;
    let oldEmail = data.userData.email;
    let image = data.userData.image;
    let subscribe_email = data.userData.subscribe_email;
    let subscribe_push = data.userData.subscribe_push;
    this.setState({
      userName: userName,
      oldEmail: oldEmail,
      email: oldEmail,
      userId: userId,
      image: image,
    });
    (subscribe_email == 1) && this.setState(prevState => ({
      subscribe: [...prevState.subscribe, "email"]
    }));
    (subscribe_push == 1) && this.setState(prevState => ({
      subscribe: [...prevState.subscribe, "push"]
    }));
    console.log(this.state);
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.userName === "") {
      isValid = false;
      errors["userName"] = "All fields are required.";
      document.getElementById("inputName").classList.add("is-invalid");
    } else {
      document.getElementById("inputName").classList.remove("is-invalid");
    }

    if (this.state.email === "") {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
      document.getElementById("inputEmail").classList.add("is-invalid");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        document.getElementById("inputEmail").classList.remove("is-invalid");
      }
    }

    this.setState({
      errors: errors,
    });

    console.log(this.state);

    return isValid;
  }

  saveProfile() {
    document.getElementById("alert").classList.remove("alert");
    if (this.validate()) {
      if (
        this.state.userName &&
        this.state.email //&&
        //this.state.image
      ) {
        let errors = {};
        this.setState({ loading: true });
        PostData("saveProfile", this.state).then((result) => {
          let responseJson = result;
          this.setState({ message: "" });
          if (responseJson.userData) {
            sessionStorage.setItem("userData", JSON.stringify(responseJson));
            this.setState({ loading: false });
            this.setState({ redirectToReferrer: true });
          } else {
            errors["saveProfile"] = result.error;
            document.getElementById("alert").classList.add("alert");
            this.setState({ loading: false });
            this.setState({
              errors: errors,
            });
          }
        });
      } else {
        this.setState({ message: "Please fill out all fields." });
      }
    }
  }

  onCheckboxChange(value) {
    this.setState({subscribe: value})
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/login"} />;
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
        theme="color"
      />
      <div className="my_pools-circle-lip my_pools-circle-lip-short box-shadow mb-4">
        <div className="my_pools-profile-info">
          <div className="avatar-edit">
            <img
              src={
                this.state.image
                  ? this.state.image
                  : "../assets/users/placeholder.gif"
              }
              alt={this.state.name}
            />
          </div>
          <div className="my_pools-add-pool">
            <a href="/edit-avatar">
              <FontAwesomeIcon icon={faUserPen} className="icon-inline" />
              Change Profile Photo
            </a>
          </div>
        </div>
      </div>
      <div className="boxed mb-4" id="Body">
        <div className="backdrop box-shadow card">
          <div className="medium-12 columns left">
            <div className="form-label-group signup-label label-edit">
              <input
                type="text"
                name="userName"
                id="inputName"
                className="form-control"
                //placeholder="Name"
                value={this.state.userName}
                required
                autoFocus=""
                onChange={this.onChange}
              />
              <label htmlFor="inputName">Name</label>
              <div className="invalid-feedback">
                {this.state.errors.userName}
              </div>
            </div>
            <div className="form-label-group signup-label label-edit">
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control"
                //placeholder="Email"
                value={this.state.email}
                required=""
                autoFocus=""
                onChange={this.onChange}
              />
              <label htmlFor="inputEmail">Email</label>
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="rainbow-p-vertical_large rainbow-p-left_xx-large">
              <CheckboxGroup
                  id="subscribeCheckbox"
                  label="I would like to receive updates about my&nbsp;pools."
                  options={[
                    { value: 'email', label: 'Email updates', disabled: false },
                    { value: 'push', label: 'Push notifications', disabled: true }]}
                  value={this.state.subscribe}
                  onChange={this.onCheckboxChange}
              />
            </div>
            <br />
            <div className="alert-warning" role="alert" id="alert">
              {this.state.errors.saveProfile}
            </div>
            <button
              type="button"
              className="btn btn-primary btn-lg mt-2 px-5"
              onClick={this.saveProfile}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Save
            </button>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default EditProfile;
