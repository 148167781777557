import React, { Component } from "react";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import ReactApexChart from 'react-apexcharts'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { images } from "../../constants";
import "./faq.scss";

class Faq extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "FAQ",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      series: [25, 30, 25, 10, 10],
      options: {
        chart: {
          width: 600,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: `"Poppins", sans-serif`,
                  fontSize: 24,
                  fontWeight: 400,
                  offsetY: 30
                },
                value: {
                  show: true,
                  fontFamily: `"Poppins", sans-serif`,
                  fontSize: 48,
                  fontWeight: 500,
                  color: ('color', '#747474'),
                  offsetY: -15
                },
                total: {
                  showAlways: false,
                  show: true,
                  label: "Total Pts",
                  fontFamily: `"Poppins", sans-serif`,
                  fontSize: 22,
                  color: ('color', '#747474'),
                }
              }
            }
          },
        },
        labels: ['Sex', 'Date', 'Time', 'Weight', 'Length'],
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient'
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              width: 436
            },
            legend: {
              position: 'bottom',
              fontFamily: `"Poppins", sans-serif`,
              fontSize: 12,
              labels:
                { colors: ('color', '#747474') },
              markers: {
                width: 12,
                height: 12
              }
            }
          }
        }]
      },
      redirectToReferrer: false,
    };
  }

  render() {
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="faq-top my_pools-circle-lip box-shadow mb-4 px-4">
        <div className="large-only boxed text-center d-none d-lg-block">
          <h1>{this.state.navbar.name}</h1>
        </div>
        <div className="text-center boxed">
          <p>These are the questions we get asked the most. If you don't see your answer below, please contact us.</p>
        </div>
        <div className="faq-contact-btn">
            <a href="/contact">
            <FontAwesomeIcon icon={faEnvelopeOpenText}
              size="lg"
              className="icon-inline"
            />
            Contact Us
          </a>
        </div>
      </div>
      <div className="faq-bottom boxed mb-4">
        <div className="card box-shadow p-4">
          <h3 className="faq-question">How is the winner determined?</h3>
          <p className="faq-answer">Each category provides points depending on the accuracy of the guesses (betz). Maximum points for guessing the correct date are [30], correct sex [25], time [25], and [10] each for weight and length, totalling in a maximum of [100] points.</p>
          <div className="faq-donut-chart mb-3">
            <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={600} />
          </div>
        </div>
        <div className="card box-shadow p-4">
          <img src={ images.vip_badge } alt="vip badge" className="faq-badge" />
          <h3 className="faq-question">What is Baby Betz VIP?</h3>
          <p className="faq-answer">With Baby Betz VIP you get UNLIMITED amount of betz on all your pools. <br/>You can enjoy all the features for free as well, but you will be limited to 5 betz per pool. If more betz are entered they will be recorded but not displayed, until you upgrade to VIP.</p>
        </div>
        <div className="card box-shadow p-4">
          <h3 className="faq-question">Can I start a Baby Pool for twins or triplets?</h3>
          <p className="faq-answer">Unfortunately we don't support multiples in a single pool at this point. We recommend setting multiple pools up for "baby 1", "baby 2" etc.</p>
        </div>
        <div className="card box-shadow p-4">
          <h3 className="faq-question">Do you allow multiple bets per person?</h3>
          <p className="faq-answer">Yes, we do. You can place additional bets under the same email address. This means people who don’t have email addresses (such as children) can still take part in the pool.</p>
        </div>
        <div className="card box-shadow p-4">
          <h3 className="faq-question">Can I change my bet after I’ve made it?</h3>
          <p className="faq-answer">Only pool admins have the ability to modify bets. The best way to change your bet is to ask an admin to do it for you, or you can submit a new one.</p>
        </div>
        <div className="card box-shadow p-4">
          <h3 className="faq-question">Does everyone I invite have to register an account?</h3>
          <p className="faq-answer">NO. This is a big advantage of Baby Betz's Baby Pools. Only the person who creates the pool has to create an account. Anyone who gets invited doesn't have to register their own account. They simply enter their betz and hit submit.</p>
        </div>
        <div className="card box-shadow p-4">
          <h3 className="faq-question">Can I get a deeper breakdown of the point system?</h3>
          <p className="faq-answer">You can score up to 100 total points combined with the following categories:</p>
          <h4>Birth Date</h4>
          <p className="faq-answer">If you guess the date of birth exactly, you get 30 points. Whith each day that your guess is off you get 5 points less.</p>
          <h4>Sex</h4>
          <p className="faq-answer">If you guess the sex correctly, you get 25 points added to your score. This one is an all or nothing type of score.</p>
          <h4>Time of Birth</h4>
          <p className="faq-answer">If you guess the time of birth exactly (to the minute), you get 25 points. Whith each minute that your guess is off you get 0.2 points less.</p>
          <h4>Weight</h4>
          <p className="faq-answer">If you guess the weight exactly, you get 10 points. For metric system, you get 1 point less for each 50g you are off; for imperial system, you get 0.5 points less for each ounce you are off.</p>
          <h4>Length</h4>
          <p className="faq-answer">If you guess the length exactly, you get 10 points. For metric system, you get 1 point less for each 0.5cm you are off; for imperial system, you get 1 point less for each 0.25" you are off.</p>
        </div>
      </div>
      </>
    );
  }
}
export default Faq;
