import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { PostData } from "../../services/postData";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "../../components";
import { confirmAlert } from "react-confirm-alert";
import { ToggleButtonGroup, ToggleButton, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faCheck, faCalendarDay, faClock } from "@fortawesome/free-solid-svg-icons";
import { DatePicker, TimePicker } from "react-rainbow-components";
import "../../styles/custom.css";
import "../placeBetz/placeBetz.scss";

class EditBetz extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Edit this Bet",
        back: true,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      bet_id: "",
      pool_title: "",
      pool_desc: "",
      date_format: "",
      due_date: "",
      time_format: "",
      units: "",
      theme: "",
      color: "",
      gender: "",
      weight: "",
      minWeightImperial: "80",
      avgWeightImperial: "120",
      maxWeightImperial: "160",
      minWeightMetric: "2",
      avgWeightMetric: "3.5",
      maxWeightMetric: "5",
      length: "",
      minLengthImperial: "18",
      avgLengthImperial: "20",
      maxLengthImperial: "22",
      minLengthMetric: "40",
      avgLengthMetric: "50",
      maxLengthMetric: "60",
      date: "",
      time: "",
      name: "",
      relation: "",
      email: "",
      comment: "",
      locale: { name: "en-US", label: "English (US)" },
      poolLoaded: false,
      betLoaded: false,
      errors: {},
      redirectToReferrer: false,
    };

    this.getPoolUnits = this.getPoolUnits.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeWeight = this.onChangeWeight.bind(this);
    this.onChangeLength = this.onChangeLength.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeRel = this.onChangeRel.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.editBet = this.editBet.bind(this);
    this.deleteBet = this.deleteBet.bind(this);
    this.deleteBetAction = this.deleteBetAction.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData")) {
      this.setPoolId();
    }
  }

  setPoolId() {
    this.setState({ pool_id: this.props.match.params.pool_id }, () => {
      this.getPoolUnits();
    });
  }

  getPoolUnits() {
    PostData("poolUnits", this.state).then((result) => {
      let responseJson = result;
      if (responseJson.poolUnits) {
        // sessionStorage.setItem("poolUnits", JSON.stringify(responseJson));
        this.setState({ poolLoaded: true });

        let due_date = new Date(responseJson.poolUnits.due_date + " 14:00 UTC");
        this.setState({
          date_format: responseJson.poolUnits.date_format,
          due_date: due_date.toISOString(),
          //date: due_date.toISOString(),
          time_format: responseJson.poolUnits.time_format,
          units: responseJson.poolUnits.units,
          theme: responseJson.poolUnits.theme,
          color: responseJson.poolUnits.color,
        });

        switch (responseJson.poolUnits.units) {
          case "metric":
            this.setState({ weight: this.state.avgWeightMetric });
            this.setState({ length: this.state.avgLengthMetric });
            break;
          case "both":
            this.setState({ weight: this.state.avgWeightImperial });
            this.setState({ length: this.state.avgLengthImperial });
            break;
          default:
            this.setState({ weight: this.state.avgWeightImperial });
            this.setState({ length: this.state.avgLengthImperial });
        }
        this.getBet();
      } else alert(result.error);
    });
  }

  getBet() {
    let bet_id = this.props.match.params.bet_id;
    this.setState({ bet_id });
    let postData = {
      pool_id: this.state.pool_id,
      bet_id,
      user_id: JSON.parse(sessionStorage.getItem("userData")).userData.user_id,
    };
    PostData("getBet", postData).then((result) => {
      let responseJson = result;
      if (responseJson.bet) {
        let date = new Date(responseJson.bet.date + " 14:00 UTC");
        this.setState({
          betLoaded: true,
          gender: responseJson.bet.gender,
          weight: parseFloat(responseJson.bet.weight),
          length: parseFloat(responseJson.bet.length),
          date: date.toISOString(),
          time: responseJson.bet.time,
          name: responseJson.bet.name,
          location: responseJson.bet.location,
          relation: responseJson.bet.relation,
          email: responseJson.bet.email,
          comment: responseJson.bet.comment,
        });
        document.getElementById("formBasicRangeWeight").value = this.state.weight;
        document.getElementById("formBasicRangeLength").value = this.state.length;
      } else alert(result.error);
    });
  }

  onChangeGender(value) {
    this.setState({ gender: value }, () => {
      let error = "";
      document.getElementById("inputGender").classList.remove("is-invalid");

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.gender = error;
        return { errors };
      });
    });
  }

  onChangeWeight(e) {
    this.setState({ weight: e.target.value });
  }

  onChangeLength(e) {
    this.setState({ length: e.target.value });
  }

  onChangeDate(value) {
    this.setState({ date: value }, () => {
      let error = "";

      if (this.state.date === "") {
        error = "Date is required.";
        document.getElementById("datePicker").classList.add("is-invalid");
      } else {
        document.getElementById("datePicker").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.date = error;
        return { errors };
      });
    });
  }

  onChangeTime(value) {
    this.setState({ time: value }, () => {
      let error = "";

      if (this.state.time === "") {
        error = "Time is required.";
        document.getElementById("timePicker").classList.add("is-invalid");
      } else {
        document.getElementById("timePicker").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.time = error;
        return { errors };
      });
    });
  }

  onChangeName(e) {
    this.setState({ name: e.target.value }, () => {
      let error = "";

      if (this.state.name === "") {
        error = "Please enter your name.";
        document.getElementById("inputName").classList.add("is-invalid");
      } else {
        document.getElementById("inputName").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.name = error;
        return { errors };
      });
    });
  }

  onChangeRel(e) {
    this.setState({ relation: e.target.value }, () => {
      let error = "";

      if (this.state.relation === "") {
        error = "Tell the parents who you are or who you are going to be.";
        document.getElementById("inputRel").classList.add("is-invalid");
      } else {
        document.getElementById("inputRel").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.relation = error;
        return { errors };
      });
    });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value }, () => {
      let error = "";

      if (this.state.email === "") {
        error = "Please enter your email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(this.state.email)) {
          error = "Please enter a valid email address.";
          document.getElementById("inputEmail").classList.add("is-invalid");
        } else {
          document.getElementById("inputEmail").classList.remove("is-invalid");
        }
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.email = error;
        return { errors };
      });
    });
  }

  onChangeComment(e) {
    let countComment = document.getElementById("countComment");
    countComment.innerHTML = e.target.value.length + " / 120";

    this.setState({ comment: e.target.value });
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.gender === "") {
      isValid = false;
      errors["gender"] = "Please select one of the genders.";
      document.getElementById("inputGender").classList.add("is-invalid");
      document.getElementById("inputGender").scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    } else {
      document.getElementById("inputGender").classList.remove("is-invalid");
    }

    if (this.state.date === "") {
      isValid = false;
      errors["date"] = "Please select a birth date.";
      document.getElementById("datePicker").classList.add("is-invalid");
    } else {
      document.getElementById("datePicker").classList.remove("is-invalid");
    }

    if (this.state.time === "") {
      isValid = false;
      errors["time"] = "Please select a time of birth.";
      document.getElementById("timePicker").classList.add("is-invalid");
    } else {
      document.getElementById("timePicker").classList.remove("is-invalid");
    }

    if (this.state.name === "") {
      isValid = false;
      errors["name"] = "Please enter your name.";
      document.getElementById("inputName").classList.add("is-invalid");
    } else {
      document.getElementById("inputName").classList.remove("is-invalid");
    }

    if (this.state.relation === "") {
      isValid = false;
      errors["relation"] =
        "Tell the parents who you are or who you are going to be.";
      document.getElementById("inputRel").classList.add("is-invalid");
    } else {
      document.getElementById("inputRel").classList.remove("is-invalid");
    }

    if (this.state.email === "") {
      isValid = false;
      errors["email"] = "Please enter your email address.";
      document.getElementById("inputEmail").classList.add("is-invalid");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        document.getElementById("inputEmail").classList.remove("is-invalid");
      }
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  editBet() {
    document.getElementById("alert").classList.remove("alert");
    if (this.validate()) {
      let errors = {};
      this.setState({ loading: true });
      PostData("editBet", this.state).then((result) => {
        let responseJson = result;
        this.setState({ message: "" });
        if (responseJson.newBetData) {
          // sessionStorage.setItem("newBetData", JSON.stringify(responseJson));
          this.setState({ loading: false });
          this.setState({ redirectToReferrer: true });
        } else {
          errors["editBet"] = result.error;
          document.getElementById("alert").classList.add("alert");
          this.setState({ loading: false });
          this.setState({
            errors: errors,
          });
        }
      });
    }
  }

  deleteBet() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h2 className="mb-4">Are you sure?</h2>
            <p>Do you really want to delete this bet?</p>
            <p>This action is <strong>not&nbsp;reversable.</strong></p>
            <button
              className="btn btn-primary btn-sm px-4"
              onClick={() => {
                this.deleteBetAction(this.state.pool_id, this.state.bet_id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
            <button
              className="btn btn-outline-primary btn-sm ml-4 px-4"
              onClick={onClose}
            >
              No
            </button>
          </div>
        );
      },
    });
  }

  deleteBetAction(pool_id, bet_id) {
    //let pool_id = document.getElementById("del").getAttribute("pools");

    let user = JSON.parse(sessionStorage.getItem("userData"));

    let postData = { user_id: user.userData.user_id, pool_id: pool_id, bet_id: bet_id };
    if (postData) {
      PostData("deleteBet", postData).then((result) => {
        if (result.success) {
          alert(result.success);
          this.setState({ redirectToReferrer: true });
        } else alert(result.error);
      });
    }
  }

  render() {
    let pool_id = this.props.match.params.pool_id;
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/pool/${pool_id}`} />;
    }

    if (!this.state.poolLoaded) {
      return (
        <>
        <Header theme="color"/>
        <MobileHeader
          name={this.state.navbar.name}
          back={this.state.navbar.back}
          backLink={`/pool/${pool_id}`}
          theme="white"
          menu={this.state.navbar.menu}
          menuLink={this.state.navbar.menuLink}
        />
        <div className="card pool-card box-shadow text-center py-4 mt-3">
          <div className="card-body px-4 py-2">
            <h4 className="card-text my-4">Loading...</h4>
          </div>
        </div>
        </>
      );
    }

    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={`/pool/${pool_id}`}
        theme="white"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <h3 className="large-only text-center d-none d-lg-block my-4">{this.state.navbar.name}</h3>
      <div className="boxed" id="Body">
        <div className="medium-12 columns mb-4">
          {/* <div className="card public-pool-card text-center box-shadow">
            <div
              className="public-pool-image"
              style={{
                backgroundImage: `url(${
                  this.state.pool_img
                    ? this.state.pool_img
                    : "https://www.theodc.net/wp-content/uploads/2018/10/Ultrasound-of-baby-in-mothers-womb..jpg"
                })`,
              }}
            ></div>
            <div className="card-body px-4 py-4">
              <h5 className="card-title">{this.state.pool_title}</h5>
              <p className="card-text">{this.state.pool_desc}</p>
            </div>
          </div>
          <a
            href="#"
            className="share-pool-btn btn btn-primary btn-lg w-100 box-shadow"
          >
            Share This Pool
          </a>
          <h6 className="text-center mb-2">Place Your Betz</h6> */}
          <div className="card text-center box-shadow p-4">
            <h4>Sex</h4>
            <ToggleButtonGroup
              type="radio"
              name="gender"
              id="inputGender"
              className="gender-button-group"
              value={this.state.gender}
              onChange={this.onChangeGender}
            >
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"M"}
                className="gender-button gender-button-boy"
              >
                <span></span>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className="toggle-button-checkmark"
                />
                <FontAwesomeIcon icon={faMars} size="4x" />
                Boy
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                size="lg"
                value={"F"}
                className="gender-button gender-button-girl"
              >
                <span></span>
                <FontAwesomeIcon
                  icon={faCheck}
                  size="sm"
                  className="toggle-button-checkmark"
                />
                <FontAwesomeIcon icon={faVenus} size="4x" />
                Girl
              </ToggleButton>
            </ToggleButtonGroup>
            <div className="invalid-feedback">{this.state.errors.gender}</div>
          </div>
          <div className="card text-center box-shadow p-4">
            <h4>Weight</h4>
            <div className="weight-display mb-3">
              {this.state.units !== "metric"
                ? `${`${parseInt(this.state.weight / 16)} lbs, ${
                    this.state.weight % 16
                  } oz`}`
                : `${(this.state.weight) + " kg"}`}
            </div>
            <Form>
              <Form.Group controlId="formBasicRangeWeight">
                <Form.Control
                  type="range"
                  className="form-control-range"
                  size="lg"
                  min={
                    this.state.units !== "metric"
                      ? this.state.minWeightImperial
                      : this.state.minWeightMetric
                  }
                  max={
                    this.state.units !== "metric"
                      ? this.state.maxWeightImperial
                      : this.state.maxWeightMetric
                  }
                  step={this.state.units !== "metric" ? "1" : "0.05"}
                  defaultValue={
                    this.state.units !== "metric"
                      ? this.state.avgWeightImperial
                      : this.state.avgWeightMetric
                  }
                  onChange={this.onChangeWeight}
                />
              </Form.Group>
            </Form>
            {this.state.units === "both" && (
              <div className="weight-display">
                {(Math.ceil(this.state.weight / 35.274 / 0.05) * 0.05).toFixed(
                  2
                ) + " kg"}
              </div>
            )}
          </div>
          
          <div className="card text-center box-shadow p-4">
            <h4>Length</h4>
            <div className="length-display mb-3">
              {this.state.units !== "metric"
                ? `${parseFloat(this.state.length).toFixed(2) + '"'}`
                : `${this.state.length + " cm"}`}
            </div>
            <Form>
              <Form.Group controlId="formBasicRangeLength">
                <Form.Control
                  type="range"
                  className="form-control-range"
                  size="lg"
                  min={
                    this.state.units !== "metric"
                      ? this.state.minLengthImperial
                      : this.state.minLengthMetric
                  }
                  max={
                    this.state.units !== "metric"
                      ? this.state.maxLengthImperial
                      : this.state.maxLengthMetric
                  }
                  step={this.state.units !== "metric" ? "0.25" : "0.1"}
                  defaultValue={
                    this.state.units !== "metric"
                      ? this.state.avgLengthImperial
                      : this.state.avgLengthMetric
                  }
                  onChange={this.onChangeLength}
                />
              </Form.Group>
            </Form>
            {this.state.units === "both" && (
              <div className="length-display">
                {(Math.ceil((this.state.length * 2.54) / 0.5) * 0.5).toFixed(
                  1
                ) + " cm"}
              </div>
            )}
          </div>
          <div className="card text-center box-shadow p-4">
            <h4>Date</h4>
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
              <DatePicker
                placeholder="Select a birth date"
                id="datePicker"
                className="xl-input xl-input-date"
                value={this.state.date}
                minDate={new Date()}
                onChange={(value) => this.onChangeDate(value)}
                valueAlignment="center"
                formatStyle="medium"
                locale={this.state.locale.name}
                icon={<FontAwesomeIcon icon={faCalendarDay} size="lg" />}
                required={true}
              />
              <div className="invalid-feedback">{this.state.errors.date}</div>
            </div>
          </div>
          <div className="card text-center box-shadow p-4">
            <h4>Time</h4>
            <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto">
              <TimePicker
                id="timePicker"
                value={this.state.time}
                onChange={(value) => this.onChangeTime(value)}
                valueAlignment="center"
                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto xl-input xl-input-time"
                hour24={this.state.time_format === "24h" ? true : false}
                icon={<FontAwesomeIcon icon={faClock} />}
                required={true}
              />
              <div className="invalid-feedback">{this.state.errors.time}</div>
            </div>
          </div>
          <div className="card text-center box-shadow py-4">
            <h4>Your Info</h4>
            <div className="medium-12 columns text-left">
              <div className="form-label-group signup-label">
                <input
                  type="text"
                  name="name"
                  id="inputName"
                  className="form-control"
                  placeholder="Your Name"
                  value={this.state.name}
                  required
                  onChange={this.onChangeName}
                />
                <label htmlFor="inputName">Your Name</label>
                <div className="invalid-feedback">{this.state.errors.name}</div>
              </div>
              <div className="form-label-group signup-label">
                <input
                  type="text"
                  name="relation"
                  id="inputRel"
                  className="form-control"
                  placeholder="Your Relation to the Baby"
                  value={this.state.relation}
                  required
                  onChange={this.onChangeRel}
                />
                <label htmlFor="inputRel">
                  Your Relation to the Baby or the Parents
                </label>
                <div className="invalid-feedback">
                  {this.state.errors.relation}
                </div>
              </div>
              <div className="form-label-group signup-label">
                <input
                  type="email"
                  name="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Your Email"
                  value={this.state.email}
                  required
                  onChange={this.onChangeEmail}
                />
                <label htmlFor="inputEmail">Your Email</label>
                <div className="invalid-feedback">{this.state.errors.email}</div>
              </div>
              <div className="form-label-group signup-label">
                <textarea
                  type="text"
                  name="comment"
                  id="inputComment"
                  row="2"
                  maxLength="120"
                  className="form-control"
                  placeholder="Comment for the parents (optional)"
                  value={this.state.comment}
                  onChange={this.onChangeComment}
                />
                <span className="badge badge-secondary" id="countComment">
                  0 / 120
                </span>
                <label htmlFor="inputComment">Comment (optional)</label>
              </div>
            </div>
          </div>
          <div className="alert-warning" role="alert" id="alert">
            {this.state.errors.editBet}
          </div>
          <div className="cancel-submit mt-4">
            <a
              href={`/pool/${pool_id}`}
              className="btn btn-outline-primary btn-lg px-4 mr-3"
            >
              Cancel
            </a>
            <button
              type="submit"
              value="Post"
              className="btn btn-primary btn-lg flex-grow-1"
              onClick={this.editBet}
            >
              <span
                className="spinner-border spinner-border-sm"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Update
            </button>
          </div>
        </div>
        <button
          onClick={this.deleteBet}
          className="btn btn-link btn-link-white btn-sm mb-3"
        >
          <FontAwesomeIcon icon={["far", "trash-alt"]} size="sm" /> Delete this
          bet
        </button>
      </div>
      </>
    );
  }
}
export default EditBetz;
