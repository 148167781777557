import React, { Component } from "react";
import { PostData } from "../../services/postData";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "..";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "./contact.scss";

const recaptchaRef = React.createRef();

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      navbar: {
        name: "Contact Us",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      },
      name: "",
      email: "",
      subject: "",
      message: "",
      captcha: "",
      loading: "",
      errors: {},
      success: "",
      redirectToReferrer: false,
    };
    this.contact = this.contact.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeSubject = this.onChangeSubject.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
  }

  contact = (event) => {
    event.preventDefault();
    document.getElementById("alert").classList.remove("alert");
    if (this.validate()) {
      console.log(this.state);
      recaptchaRef.current.execute();
      console.log("post recaptchaRef");
      if (
        this.state.name &&
        this.state.email &&
        this.state.subject &&
        this.state.message
      ) {
          let errors = {};
          this.setState({ loading: true });
          PostData("contact", this.state).then((result) => {
            let responseJson = result;
            console.log("responseJson: " + JSON.stringify(responseJson));
            this.setState({ errorMessage: "" });
            if (responseJson.success) {
              this.setState({
                loading: false,
                success: result.success
              });
              document.getElementById("success").classList.add("alert");
              // this.setState({ redirectToReferrer: true });
            } else {
              //alert(result.error);
              errors["contact"] = result.error;
              document.getElementById("alert").classList.add("alert");
              this.setState({ loading: false });
              this.setState({
                errors: errors,
              });
            }
          });
      } else {
        this.setState({ errorMessage: "Please fill out all fields." });
      }
    };
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
  }

  validate() {
    let errors = {};
    let isValid = true;

    if (this.state.name === "") {
      isValid = false;
      errors["name"] = "Please enter your name.";
      document.getElementById("inputName").classList.add("is-invalid");
    } else {
      document.getElementById("inputName").classList.remove("is-invalid");
    }

    if (this.state.email === "") {
      isValid = false;
      errors["email"] = "Please enter your email address.";
      document.getElementById("inputEmail").classList.add("is-invalid");
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        document.getElementById("inputEmail").classList.remove("is-invalid");
      }
    }

    if (this.state.subject === "") {
      isValid = false;
      errors["subject"] = "Please describe the subject.";
      document.getElementById("inputSubject").classList.add("is-invalid");
    } else {
      document.getElementById("inputSubject").classList.remove("is-invalid");
    }

    if (this.state.message === "") {
      isValid = false;
      errors["message"] = "All fields are required.";
      document.getElementById("inputMessage").classList.add("is-invalid");
    } else {
      document.getElementById("inputMessage").classList.remove("is-invalid");
    }

    this.setState({
      errors: errors,
    });

    return isValid;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeName(e) {
    this.setState({ name: e.target.value }, () => {
      let error = "";

      if (this.state.name === "") {
        error = "Please enter your name.";
        document.getElementById("inputName").classList.add("is-invalid");
      } else {
        document.getElementById("inputName").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.name = error;
        return { errors };
      });
    });
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value }, () => {
      let error = "";

      if (this.state.email === "") {
        error = "Please enter your email address.";
        document.getElementById("inputEmail").classList.add("is-invalid");
      } else {
        var pattern = new RegExp(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(this.state.email)) {
          error = "Please enter a valid email address.";
          document.getElementById("inputEmail").classList.add("is-invalid");
        } else {
          document.getElementById("inputEmail").classList.remove("is-invalid");
        }
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.email = error;
        return { errors };
      });
    });
  }

  onChangeSubject(e) {
    this.setState({ subject: e.target.value }, () => {
      let error = "";

      if (this.state.subject === "") {
        error = "Please describe the subject.";
        document.getElementById("inputSubject").classList.add("is-invalid");
      } else {
        document.getElementById("inputSubject").classList.remove("is-invalid");
      }

      this.setState((prevState) => {
        let errors = Object.assign({}, prevState.errors);
        errors.subject = error;
        return { errors };
      });
    });
  }

  onChangeMessage(e) {
    let countMessage = document.getElementById("countMessage");
    countMessage.innerHTML = e.target.value.length + " / 250";

    this.setState({ message: e.target.value });
  }
  
  onCaptcha(value) {
    console.log("Captcha value: ", value);
    console.log("Executes after 'onCaptcha'");
  }

  render() {
    return (
      <>
      <Header theme="color"/>
      <MobileHeader
        name={this.state.navbar.name}
        back={this.state.navbar.back}
        backLink={this.state.navbar.backLink}
        theme="color"
        menu={this.state.navbar.menu}
        menuLink={this.state.navbar.menuLink}
      />
      <div className="faq-top my_pools-circle-lip my_pools-circle-lip-shorter box-shadow mb-4 px-4">
        <div className="large-only boxed text-center d-none d-lg-block">
          <h1>{this.state.navbar.name}</h1>
        </div>
        <div className="text-center boxed">
          <p>We'd love to hear from you!</p>
        </div>
        <FontAwesomeIcon icon={faEnvelopeOpenText} size="5x"/>
      </div>
      <h3 className="large-only text-center d-none d-lg-block my-4">Get in touch with us.</h3>
      <div className="boxed mb-4" id="sBody">
        <div className="backdrop box-shadow mb-4">
          <div className="medium-12 columns left">
            <h6 className="center mb-4">We're here to help answer any question you might have. We look forward to hearing from you.</h6>
            {/* <form onSubmit={this.contact}> */}
            <form onSubmit={this.onCaptcha}>
            <div className="form-label-group signup-label">
              <input
                type="text"
                name="name"
                id="inputName"
                className="form-control"
                placeholder="Name"
                required=""
                autoFocus=""
                onChange={this.onChangeName}
              />
              <label htmlFor="inputName">Name</label>
              <div className="invalid-feedback">{this.state.errors.name}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type="email"
                name="email"
                id="inputEmail"
                className="form-control"
                placeholder="Email"
                required=""
                autoFocus=""
                onChange={this.onChangeEmail}
              />
              <label htmlFor="inputEmail">Email</label>
              <div className="invalid-feedback">{this.state.errors.email}</div>
            </div>
            <div className="form-label-group signup-label">
              <input
                type="text"
                name="subject"
                id="inputSubject"
                className="form-control"
                placeholder="Subject"
                required=""
                autoFocus=""
                onChange={this.onChangeSubject}
              />
              <label htmlFor="inputSubject">Subject</label>
              <div className="invalid-feedback">
                {this.state.errors.subject}
              </div>
            </div>
            <div className="form-label-group signup-label">
              <textarea
                type="text"
                name="message"
                id="inputMessage"
                rows="5"
                maxLength="250"
                className="form-control"
                placeholder="Your Message"
                onChange={this.onChangeMessage}
              />
              <span className="badge badge-secondary" id="countMessage">
                0 / 250
              </span>
              <label htmlFor="inputMessage">Message</label>
              <div className="invalid-feedback">
                {this.state.errors.message}
              </div>
            </div>
              
            <div className="alert-danger" role="alert" id="alert">
              {this.state.errors.contact}
            </div>
            <div className="alert-success" role="alert" id="success">
              {this.state.success}
            </div>
            <button
              className="btn btn-lg btn-primary btn-block my-4"
              type="submit"
                  onClick={this.contact}
                  // onClick={() => { recaptchaRef.current.execute(); }}
            >
              <span
                className="spinner-border spinner-border-sm g-recaptcha"
                style={{
                  display: this.state.loading ? "inline-block" : "none",
                }}
                role="status"
                aria-hidden="true"
              ></span>
              Submit <FontAwesomeIcon icon={faPaperPlane} />
            </button>
            <ReCAPTCHA
              size="invisible"
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={this.onCaptcha}
            />
            <input type="submit" hidden />
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}
export default Contact;
