import React, { Component } from "react";
import MobileHeader from "../mobileHeader/mobileHeader";
import { Header } from "../../components";
import {Helmet} from "react-helmet";
import { Parallax, Background } from 'react-parallax';
import { images } from "../../constants";
import "./welcome.scss";

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navbar: {
        name: "",
        back: false,
        backLink: "",
        menu: true,
        menuLink: "",
      }
    };
  };
  render() {
    return (
      <>
        <Header />
        <div className="app__welcome-logo">
          <a href="../" target="_self"><img src={images.logo_white} alt="logo"/></a>
        </div>
        <div className="app__welcome-mobile-header">
        <MobileHeader
          name={this.state.navbar.name}
          back={this.state.navbar.back}
          backLink={this.state.navbar.backLink}
          menu={this.state.navbar.menu}
          menuLink={this.state.navbar.menuLink}
          theme="photo"
          logout={this.logout}
          redirectToReferrer={this.state.redirectToReferrer}
        />
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>{ `Baby Betz - ${this.state.pool_title}` }</title>
          <meta name="title" key="title" content="Baby Betz - Betting on babies is always fun!" />
          <meta name="description" key="description" content="Baby Betz makes it easy to let family and friends have some fun guessing Mommy’s due date, babies sex, weight, and all kinds of fun stats. Start a baby pool today!" />
          <meta property="og:image" key="og:image" content="https://app.squareseed.co/assets/images/og-image.jpg" />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:title" key="og:title" content="Baby Betz - Betting on babies is always fun!" />
          <meta property="og:description" key="og:description" content="Baby Betz makes it easy to let family and friends have some fun guessing Mommy’s due date, babies sex, weight, and all kinds of fun stats. Start a baby pool today!" />
        </Helmet> */}
        </div>
        <div className="app__welcome">
          <Parallax
            className="app__welcome-image"
              // bgImage={require('../../../src/assets/hero_pregnant_belly.jpg')}
              bgImage={images.hero_pregnant_belly}
              bgImageAlt="pregnant belly"
              strength={200}
          >
          <div className="app__welcome-top" id="welcome">
              <h4>
                Everything is a competition!
              </h4>
              <h2>Start a baby guessing game</h2>
              <a className="app__welcome-button btn btn-lg btn-primary my-5 px-5" href="/my-profile">
                START
              </a>
          </div>
            <div className="app__welcome-image-bottom"><img src={images.bottom_white_wave_01} alt="bottom_wave"/></div>
          </Parallax>
          <div className="app__welcome-steps">
            <div className="app__welcome-step">
              <div className="app_welcome-steps-image">
                <img src={images.smartphone_mockup_01} alt="smartphone mockup"/>
              </div>
              <div className="app_welcome-steps-text">
                <h2 className="pacifico-regular">Create Your Pool</h2>
                <p>Enter a few details about the upcoming baby and customize your pool. Then all you have to do is share the fun! Invite family and friends to place their bets.</p>
                <a className="app__welcome-button btn btn-lg btn-primary mt-2 px-5" href="/my-profile">
                START
              </a>
              </div>
            </div>
            <div className="app__welcome-step">
              <div className="app_welcome-steps-image">
                <img src={images.smartphone_mockup_02} alt="smartphone mockup share"/>
              </div>
              <div className="app_welcome-steps-text">
                <h2 className="pacifico-regular">Share with Friends &amp; Family</h2>
                <p>Invite friends & family to add their hunches about the baby via Facebook, Twitter, email or even text.</p>
              </div>
            </div>
            <div className="app__welcome-step">
              <div className="app_welcome-steps-image">
                <img src={images.smartphone_mockup_03} alt="smartphone mockup"/>
              </div>
              <div className="app_welcome-steps-text">
                <h2 className="pacifico-regular">Get Predictions</h2>
                <p>Place your bets on the baby's arrival date, sex, weight and length. Follow along as the predictions roll in. See how your bet stacks up to the rest as the due date approaches.</p>
                <a className="app__welcome-button btn btn-lg btn-primary mt-2 px-5" href="/my-profile">
                START
              </a>
              </div>
            </div>
            <div className="app__welcome-step text-center">
              <div className="app_welcome-steps-image">
                <img src={images.smartphone_mockup_04} alt="smartphone mockup" className="mb-2"/>
              </div>
              <div className="app_welcome-steps-text">
                <h2 className="pacifico-regular">Crown a Winner</h2>
                <p>Once baby arrives, enter the actual info. The scores will calculate automatically and a winner will be declared! And you can share a custom virtual announcement of the little one with the world!</p>
                <a className="app__welcome-button btn btn-lg btn-primary mt-2 px-4" href="/my-profile">
                TRY NOW
              </a>
              </div>
            </div>
          </div>
          <img src={images.top_white_wave_01} alt="white wave" className="app__welcome-wave-footer"/>
        </div>
      </>
    );
  }
}
export default Welcome;
